import { gql } from "@apollo/client"
import {
  campaignListingParts,
  campaignDetailParts,
  eventCampaignDetailParts,
  campaignListingPartsWithLocale,
} from "@tmu/apollo/storefront/fragments/campaign"
import {
  partnerListingParts,
  partnerListingPartsWithLocale,
} from "@tmu/apollo/storefront/fragments/partner"
import {
  merchantStoreParts,
  merchantStorePartsWithLocale,
} from "@tmu/apollo/storefront/fragments/merchant"
import { capitalize } from "@tmu/utils/string"

export const CAMPAIGN_LISTING_QUERY = gql`
  query CampaignListing(
    $categories: [ID]
    $partner: [ID]
    $country: String
    $campaignType: CampaignsCampaignCampaignTypeChoices
    $fundraiserType: [String]
    $orderBy: String
    $first: Int
    $pacMultiplier: Decimal
    $after: String
    $search: String
    $user: [ID]
    $isSupporter: Boolean
    $isEligibleForSupporterEvent: Boolean
    $isEligibleForSupporterCollection: Boolean
    $visibilityStatus: [String]
    $status_In: [String]
    $campaignOrigin: [String]
    $startsAt_Lt: DateTime
    $startsAt_Lte: DateTime
    $startsAt_Gt: DateTime
    $startsAt_Gte: DateTime
    $deadline_Lte: DateTime
    $isStoreSupporter: Boolean
    $store: [ID]
  ) {
    allCampaigns(
      first: $first
      after: $after
      categories: $categories
      partner: $partner
      country: $country
      pacMultiplier: $pacMultiplier
      campaignType: $campaignType
      fundraiserType: $fundraiserType
      orderBy: $orderBy
      search: $search
      user: $user
      isSupporter: $isSupporter
      visibilityStatus: $visibilityStatus
      status_In: $status_In
      campaignOrigin: $campaignOrigin
      isEligibleForSupporterEvent: $isEligibleForSupporterEvent
      isEligibleForSupporterCollection: $isEligibleForSupporterCollection
      startsAt_Lt: $startsAt_Lt
      startsAt_Lte: $startsAt_Lte
      startsAt_Gt: $startsAt_Gt
      startsAt_Gte: $startsAt_Gte
      deadline_Lte: $deadline_Lte
      isStoreSupporter: $isStoreSupporter
      store: $store
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...campaignListingParts
          stripePublicKey
          categories {
            edges {
              node {
                id
                slug
                name
                icon
              }
            }
          }
          partner {
            ...partnerListingParts
          }
          store {
            ...merchantStoreParts
          }
        }
      }
    }
  }
  ${campaignListingParts}
  ${partnerListingParts}
  ${merchantStoreParts}
`

export const CAMPAIGN_LISTING_QUERY_WITH_LOCALE = (locale) => gql`
  query CampaignListing(
    $categories: [ID]
    $partner: [ID]
    $country: String
    $campaignType: CampaignsCampaignCampaignTypeChoices
    $fundraiserType: [String]
    $orderBy: String
    $first: Int
    $pacMultiplier: Decimal
    $after: String
    $search: String
    $user: [ID]
    $isSupporter: Boolean
    $isEligibleForSupporterEvent: Boolean
    $isEligibleForSupporterCollection: Boolean
    $visibilityStatus: [String]
    $campaignOrigin: [String]
    $startsAt_Lt: DateTime
    $startsAt_Gte: DateTime
    $isStoreSupporter: Boolean
    $store: [ID]
  ) {
    allCampaigns(
      first: $first
      after: $after
      categories: $categories
      partner: $partner
      country: $country
      pacMultiplier: $pacMultiplier
      campaignType: $campaignType
      fundraiserType: $fundraiserType
      orderBy: $orderBy
      search: $search
      user: $user
      isSupporter: $isSupporter
      visibilityStatus: $visibilityStatus
      campaignOrigin: $campaignOrigin
      isEligibleForSupporterEvent: $isEligibleForSupporterEvent
      isEligibleForSupporterCollection: $isEligibleForSupporterCollection
      startsAt_Lt: $startsAt_Lt
      startsAt_Gte: $startsAt_Gte
      isStoreSupporter: $isStoreSupporter
      store: $store
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...campaignListingParts
          terminalDescription
          stripePublicKey
          categories {
            edges {
              node {
                id
                slug
                name
                icon
              }
            }
          }
          partner {
            ...partnerListingParts
          }
          store {
            ...merchantStoreParts
          }
        }
      }
    }
  }
  ${campaignListingPartsWithLocale(locale)}
  ${partnerListingPartsWithLocale(locale)}
  ${merchantStorePartsWithLocale(locale)}
`
export const TERMINAL_CAMPAIGN_LISTING_QUERY = gql`
  query CampaignListing(
    $categories: [ID]
    $fundraiserType: [String]
    $orderBy: String
    $first: Int
    $after: String
    $search: String
    $visibilityStatus: [String]
  ) {
    allCampaigns(
      first: $first
      after: $after
      categories: $categories
      fundraiserType: $fundraiserType
      orderBy: $orderBy
      search: $search
      visibilityStatus: $visibilityStatus
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          ...campaignListingParts

          partner {
            ...partnerListingParts
          }
          store {
            ...merchantStoreParts
          }
        }
      }
    }
  }

  fragment campaignListingParts on CampaignNode {
    id
    slug
    nameEn
    nameEs
    nameIt
    name
    isDefaultCampaign
    image {
      url
      cache
    }
    terminalDescription
    stripePublicKey
    goalAmount
    fundedAmount
    donationCount
    campaignType
    progress
    pacMultiplier
    tiers {
      edges {
        node {
          id
          description
          donationAmount
          donationAmountOperator
          isSoldOut
        }
      }
    }
  }

  fragment partnerListingParts on PartnerNode {
    id
    slug
    name
    displayName
    logo
    taxId
  }

  fragment merchantStoreParts on MerchantStoreNode {
    id
    slug
    name
    displayName
    logo
  }
`

export const REGISTER_READER_MUTATION = gql`
  mutation RegisterReader(
    $registrationCode: String!
    $label: String!
    $location: String
  ) {
    registerReader(
      registrationCode: $registrationCode
      label: $label
      location: $location
    ) {
      reader {
        id
        deviceType
        label
        location
        status
      }
      errors {
        field
        messages
      }
    }
  }
`

export const TERMINAL_CAMPAIGN_DETAIL_QUERY = gql`
  query CampaignDetail($slug: String!) {
    campaign(slug: $slug) {
      id
      slug
      name
      nameEn
      nameEs
      nameIt
      video
      isDefaultCampaign
      images {
        edges {
          node {
            id
            image
          }
        }
      }
      terminalDescription
      stripePublicKey
      goalAmount
      fundedAmount
      donationCount
      campaignType
      progress
      pacMultiplier
      tiers {
        edges {
          node {
            id
            description
            donationAmount
            donationAmountOperator
            isSoldOut
          }
        }
      }
      partner {
        id
        slug
        name
        displayName
        logo
        taxId
        images {
          edges {
            node {
              id
              image
            }
          }
        }
      }
      store {
        id
        slug
        name
        displayName
        logo
      }
    }
  }
`

export const TERMINAL_CREATE_DONATION = gql`
  mutation Donation($input: DonationMutationInput!) {
    createDonation(input: $input) {
      donation {
        id
        amount
        status
        stripePaymentIntentId
      }
      errors {
        field
        messages
      }
    }
  }
`

export const TERMINAL_CANCEL_DONATION = gql`
  mutation CancelPaymentIntent($paymentIntentId: String!, $readerId: String!) {
    cancelPaymentIntent(
      paymentIntentId: $paymentIntentId
      readerId: $readerId
    ) {
      paymentIntent {
        id
        clientSecret
      }
    }
  }
`

export const TERMINAL_DONATION_QUERY = gql`
  query DonationStatus($donationId: UUID!) {
    limitedDonation(donationId: $donationId) {
      status
    }
  }
`
export const TERMINAL_READER_QUERY = gql`
  query TerminalStatus($id: String!) {
    terminalReader(id: $id) {
      id
      deviceType
      label
      location
      status
    }
  }
`
export const TERMINAL_SIMULATE_CARD_PAYMENT = gql`
  mutation SimulateCardPayment($readerId: String!) {
    simulatedCardPayment(readerId: $readerId) {
      simulatedPayment {
        id
        deviceType
        label
        location
        livemode
        object
        status
        action {
          failureCode
          failureMessage
          status
          type
        }
      }
    }
  }
`

export const FEATURED_CAMPAIGN_QUERY = gql`
  query FeaturedCampaigns($first: Int) {
    allCampaigns(first: $first, isFeatured: true, orderBy: "name") {
      edges {
        node {
          ...campaignListingParts
          categories {
            edges {
              node {
                id
                slug
                name
                icon
              }
            }
          }
          partner {
            ...partnerListingParts
          }
        }
      }
    }
  }
  ${campaignListingParts}
  ${partnerListingParts}
`

export const CAMPAIGN_DETAIL_QUERY = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return gql`
  query CampaignDetail($slug: String!) {
    campaign(slug: $slug) {
      id
      slug
      name
      name${localeCapital}
      stripePublicKey
      campaignType
      pacMultiplier
      fundedAmount
      eventAddress
      eventMapLink
      virtualEventLink
      deadline
      startsAt
      status
      isAvailable
      goalAmount
      donationCount
      visibilityStatus
      fundedAmount
      shortDescription
      isDefaultCampaign
      enableDonationNote
      isVoucher
      isReservationRequired
      recurringIsEnabled
      recurringAmountOne
      recurringAmountTwo
      recurringAmountThree
      inventoryRecord {
        maxTickets
        isUnlimitedTickets
        isSoldOut
        stockLeft
        booked
        reserved
        acquired
        used
      }
      subscriptionProducts {
      edges {
        node {
          id
          description
          stripeStatementDescriptor
        }
      }
    }
      supportedCampaign{
        id
        slug
        name
        nameEn
        nameIt
        nameEs
      }
      shortDescription${localeCapital}
      description
      description${localeCapital}
      video
      image {
        url
        cache
      }
      user {
        id
        fullName
        profileImage
      }
      tiers {
        edges {
          node {
            id
            description
            donationAmount
            donationAmountOperator
            maxTickets
            isUnlimitedTickets
            description${localeCapital}
            isSoldOut
            stockLeft
            booked
            acquired
            used
            __typename
          }
        }
      }
      supporterCause{
        id
        slug
        name${localeCapital}
        icon
      }
      images {
        edges {
          node {
            id
            image
          }
        }
      }
      partner {
        id
        slug
        displayName
        name
        logo
        logo${localeCapital}
      }
      store {
        id
        slug
        name
        displayName
        logo
        maxPacDiscount
        logo
        isDisplayed
        defaultOffer {
          image
          offerType
        }
        offers {
          edges {
            node {
              id
              name
              isDefaultOffer
              offerType
            }
          }
        }
      }
    }
    topDonor: allDonors(first: 1,campaign_Slug: $slug, orderBy: "-total") {
        edges {
          node {
            id
            total
            latestDonationDate
            isAnonymous
            user {
              fullName
              profileImage
            }
          }
        }
  } 
      latestDonors: allDonors(first: 5, campaign_Slug: $slug, orderBy: "-latestDonationDate") {
        totalCount
        edges {
          node {
            id
            total
            latestDonationDate
            isAnonymous
            user {
              id
              fullName
              profileImage
            }
          }
        }
      }
  }
`
}
export const CAMPAIGN_EVENT_DETAIL_QUERY = gql`
  query CampaignDetail($slug: String!) {
    campaign(slug: $slug) {
      ...eventCampaignDetailParts
      categories {
        edges {
          node {
            id
            nameEn
            nameIt
            nameEs
            slug
            icon
          }
        }
      }
      partner {
        ...partnerListingParts
        campaigns(first: 4) {
          edges {
            node {
              ...campaignListingParts
            }
          }
        }
      }
      images {
        edges {
          node {
            id
            image
          }
        }
      }
      faqs {
        edges {
          node {
            id
            questionEn
            questionIt
            questionEs
            answerEn
            answerIt
            answerEs
          }
        }
      }
      updates {
        edges {
          node {
            id
            titleEn
            titleIt
            titleEs
            contentEn
            contentIt
            contentEs
            created
            modified
            date
            image
          }
        }
      }
    }
  }
  ${campaignListingParts}
  ${partnerListingParts}
  ${eventCampaignDetailParts}
`

export const DONATION_SUCCESS_QUERY = gql`
  query DonationSuccess($slug: String!, $donationId: ID!) {
    campaign(slug: $slug) {
      ...campaignDetailParts
      user {
        id
        fullName
        profileImage
      }
      partner {
        id
        slug
        name
      }
      store {
        id
        slug
        name
        logo
        defaultOffer {
          offerType
        }
      }
    }
    donation(id: $donationId) {
      id
      amount
      earnedPac
      isAnonymous
      merchantPacReconciliation {
        id
        totalAmount
        donationAmount
        totalPacsRequired
        normalizedDonationAmount
        pacMultiplierSurplus
        pacAmountAfterNormalizedDonation
        netEarnedPacsAfterDonation
        purchaseAmountAfterDiscount
      }
      donateByShoppingMerchant {
        id
        name
      }
    }
  }
  ${campaignDetailParts}
`

export const DONATION_TIER_QUERY = gql`
  query DonationTier($id: ID!) {
    donationTier(id: $id) {
      id
      donationAmount
      donationAmountOperator
      stockLeft
      booked
      acquired
      used
      isUnlimitedTickets
      isSoldOut
      descriptionEn
      descriptionIt
      descriptionEs
      maxTickets
      campaign {
        ...campaignDetailParts
      }
    }
  }
  ${campaignDetailParts}
`

export const CAMPAIGN_DETAIL_QUERY_FOR_DONATION = gql`
  query CampaignDetail($slug: String!) {
    campaign(slug: $slug) {
      id
      slug
      name
      nameEn
      nameIt
      nameEs
      deadline
      startsAt
      image {
        url
        cache
      }
      descriptionEn
      descriptionEs
      descriptionIt
      stripePublicKey
      goalAmount
      donationCount
      fundedAmount
      campaignType
      pacMultiplier
      isDefaultCampaign
      enableDonationNote
      subscriptionProducts {
        edges {
          node {
            id
            description
            stripeStatementDescriptor
          }
        }
      }
      supportedCampaign {
        id
        slug
        name
        nameEn
        nameIt
        nameEs
      }
      images {
        edges {
          node {
            id
            image
            order
            __typename
          }
        }
      }
      partner {
        logo
        logoEn
        logoEs
        logoIt
        fundedAmount
        donationCount
        slug
        displayName
        name
        taxId
      }
      store {
        id
        slug
        name
        displayName
        logo
        defaultOffer {
          offerType
        }
      }
      tiers {
        edges {
          node {
            id
            description
            donationAmount
            donationAmountOperator
            stockLeft
            booked
            acquired
            used
            maxTickets
            isUnlimitedTickets
            isSoldOut
            __typename
          }
        }
      }
    }
  }
`

export const CAMPAIGN_TOTAL_COUNT_QUERY = gql`
  query CampaignTotalCount($user: [ID], $orderBy: String) {
    allCampaigns(first: 1, user: $user, orderBy: $orderBy) {
      totalCount
    }
  }
`

export const EVENT_DETAIL_QUERY = ({ locale }) => {
  const localeCapital = capitalize(locale)

  return gql`
  query CampaignDetail($slug: String!) {
    campaign(slug: $slug) {
      id
      slug
      name
      name${localeCapital}
      campaignType
      fundedAmount
   
      deadline
      startsAt
      status
  
      donationCount
      visibilityStatus
      fundedAmount
      shortDescription
      isVoucher
      inventoryRecord {
        maxTickets
        isUnlimitedTickets
        isSoldOut
        stockLeft
        booked
        reserved
        acquired
        used
      }
      shortDescription${localeCapital}
      description
      description${localeCapital}
      tiers {
        edges {
          node {
            id
            description
            donationAmount
            donationAmountOperator
            maxTickets
            isUnlimitedTickets
            description${localeCapital}
            isSoldOut
            stockLeft
            booked
            acquired
            used
            __typename
          }
        }
      }
    }
  }
`
}
