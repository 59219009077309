/* eslint-disable no-useless-escape */
// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
import queryString from "query-string"
import { isBrowser } from "@tmu/utils/auth"
import { format } from "date-fns"

export const escapeRegexCharacters = (str) => {
  return str && str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

export const youtubeVimeoUrlControl = (str) => {
  return (
    str &&
    /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/.test(
      str
    )
  )
}

export const getInitials = (str, glue = true) => {
  let initials = str.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g)
  if (initials?.length > 2) initials = [initials[0], initials.pop()]
  if (glue) {
    return initials?.join("") ?? ""
  }

  return initials
}

export const capitalize = (str) =>
  str?.toLowerCase().replace(/\b\w/g, (m) => m.toUpperCase())

export const truncateHash = (hash = "", limit = 20) =>
  hash?.length > limit ? `${hash.substr(0, limit)}...` : hash

export const getValueForLocale = (
  inputObj,
  key,
  locale,
  defaultLocale = "en"
) => {
  const localeSuffix = capitalize(locale)
  const propName = `${key}${localeSuffix}`
  const defaultPropName = `${key}${capitalize(defaultLocale)}`

  if (!inputObj) {
    return ""
  }

  return inputObj?.[propName]?.length > 0
    ? inputObj[propName]
    : inputObj[defaultPropName] || inputObj[key]
}

export function randomString(length = 32) {
  const bytes = new Uint8Array(length)
  const result = []
  const charset =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~"

  const crypto = window.crypto || window.msCrypto
  const random = crypto.getRandomValues(bytes)
  for (let i = 0; i < random.length; i += 1)
    result.push(charset[random[i] % charset.length])

  return result.join("")
}

export const removeAllHTMLTags = (str = "") => {
  const regex = /\<.*?\>/gs
  const subst = ``
  return str?.replace(regex, subst) ?? ""
}

export const removeEmptyHTMLTags = (str = "") => {
  const regex = /<[^>]*>\s*<\/[^>]*>/gs
  const subst = ``
  return str?.replace(regex, subst) ?? ""
}

export const getQueryParams = (url) => {
  return isBrowser
    ? queryString.parse(url || window?.location?.search, {
        arrayFormat: "comma",
        skipEmptyString: true,
      })
    : {}
}

export const getDateWithZeroHours = (d) => {
  const dateFormat = "yyyy-MM-dd"

  if (d instanceof Date) {
    return new Date(format(d, dateFormat))
  }
  return null
}

export const htmlToPlainText = (content) => {
  if (!content) {
    return ""
  }
  // Create a new div element
  var tempDivElement = document?.createElement("div")

  // Set the HTML content with the given value
  tempDivElement.innerHTML = content

  let text = tempDivElement?.textContent ?? tempDivElement?.innerText ?? ""

  if (text === "undefined") {
    text = ""
  }

  // Retrieve the text property of the element
  return text
}
