import React, { useRef, useImperativeHandle, forwardRef } from "react"
import PropTypes from "prop-types"
import { StyledFilterBar } from "./index.styles"
import { SearchBoxFilter, Dropdown } from "@tmu/components/common"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"

const FilterBar = forwardRef(
  (
    {
      handleSearch,
      searchFieldName,
      selectedCategories,
      setSelectedCategories,
      allCampaignCategories,
    },
    ref
  ) => {
    const { isTablet, isDesktop, isWide } = getAllScreenTypes()
    const isMobile = !isWide && !isDesktop && !isTablet

    useImperativeHandle(ref, () => ({
      handleSearchFethMore: (value) => handleSearchFethMore(value),
    }))

    const { formatMessage } = useIntl()
    const searchBoxRef = useRef()

    const handleSearchFethMore = () => {
      searchBoxRef?.current?.handleFetchMore()
    }

    return (
      <StyledFilterBar>
        <SearchBoxFilter
          ref={searchBoxRef}
          className="search-box"
          fieldName={searchFieldName}
          handleSearch={handleSearch}
          // leftIcon
        />
        <Dropdown
          className="category-dropdown"
          isMulti={true}
          placeholder={formatMessage({
            id: "dashboard::campaign::categoriesAll",
            defaultMessage: "All categories",
          })}
          options={allCampaignCategories?.map(({ id, name, icon }) => ({
            value: id,
            label: name,
            icon: icon,
          }))}
          value={selectedCategories}
          onChange={(categories) => {
            setSelectedCategories(categories)
          }}
          formatOptionLabel={({ label, icon }, { context }) => (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: context === "menu" ? "25px 1fr" : "1fr",
                alignItems: "center",
              }}>
              {context === "menu" && icon && <i className={icon} />}
              {label}
            </div>
          )}
        />
      </StyledFilterBar>
    )
  }
)

export default FilterBar
