import { useEffect } from "react"
import { useAuth } from "./useAuth"
import { PAYMENT_TYPES } from "../apollo/constants"

const { useLazyQuery, useMutation } = require("@apollo/client")
const { useApolloApiClients } = require("@tmu/apollo/client")
const {
  SAVED_CARDS_QUERY,
  DELETE_CARD_QUERY,
  SUBSCRIPTIONS_QUERY,
  UPDATE_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  CREATE_CLIENT_SECRET,
  SAVED_PAYPAL_ACCOUNTS_QUERY,
  ALL_SAVED_CARDS_ACCOUNTS_QUERY,
  CREATE_CLIENT_SECRET_WITH_REGISTRATION,
} = require("@tmu/apollo/storefront/queries/donation")

const useCreditCards = ({ callImmediate = false }) => {
  const { storefrontClient } = useApolloApiClients()
  const { user } = useAuth()

  const [callSavedCards, { data, loading, called, refetch, fetchMore }] =
    useLazyQuery(SAVED_CARDS_QUERY, {
      variables: {},
      client: storefrontClient,
      fetchPolicy: "network-only",
    })

  const [callPaypalAccounts, paypalAccountsData] = useLazyQuery(
    SAVED_PAYPAL_ACCOUNTS_QUERY,
    {
      variables: {},
      client: storefrontClient,
      fetchPolicy: "network-only",
    }
  )

  const [callAllSavedAccounts, allSavedAccountsData] = useLazyQuery(
    ALL_SAVED_CARDS_ACCOUNTS_QUERY,
    {
      variables: {},
      client: storefrontClient,
      fetchPolicy: "network-only",
    }
  )

  const [
    callSubscriptions,
    {
      data: subscriptionData,
      called: subscriptionCalled,
      refetch: subscriptionRefresh,
      fetchMore: subscriptionFetchMore,
      loading: subscriptionsLoading,
    },
  ] = useLazyQuery(SUBSCRIPTIONS_QUERY, {
    variables: {},
    client: storefrontClient,
    fetchPolicy: "network-only",
  })

  const [deleteCreditCard, { data: deleteCardData, errors: deleteCardErrors }] =
    useMutation(DELETE_CARD_QUERY, {
      client: storefrontClient,
      refetchQueries: [
        {
          query: ALL_SAVED_CARDS_ACCOUNTS_QUERY,
        },
      ],
    })

  const [
    callCreateClientSecretMutation,
    { data: clientSecretData, errors: clientSecretError },
  ] = useMutation(CREATE_CLIENT_SECRET, {
    client: storefrontClient,
    variables: { stripePaymentMethodType: PAYMENT_TYPES.CARD },
  })

  const [
    callCreateClientSecretWithRegisterMutation,
    {
      data: clientSecretDataWithRegister,
      errors: clientSecretErrorWithRegister,
    },
  ] = useMutation(CREATE_CLIENT_SECRET_WITH_REGISTRATION, {
    client: storefrontClient,
    // variables: { stripePaymentMethodType: PAYMENT_TYPES.CARD },
  })

  const [
    callUpdateSubscription,
    { data: updateSubscriptionData, errors: updateSubscriptionError },
  ] = useMutation(UPDATE_SUBSCRIPTION, {
    client: storefrontClient,
  })

  const [
    callCancelSubscription,
    { data: cancelSubscriptionData, errors: cancelSubscriptionError },
  ] = useMutation(CANCEL_SUBSCRIPTION, {
    client: storefrontClient,
  })

  const deleteCard = (cardId) => {
    if (!user?.id) {
      return
    }
    return deleteCreditCard({ variables: { input: { cardId } } })
  }

  const updateSubscription = (subscription, stripePaymentMethodId) => {
    if (!user?.id) {
      return
    }
    return callUpdateSubscription({
      variables: { input: { stripePaymentMethodId, subscription } },
    })
  }

  const cancelSubscription = (subscription) => {
    if (!user?.id) {
      return
    }
    return callCancelSubscription({
      variables: { input: { subscription } },
    })
  }

  useEffect(() => {
    if (callImmediate) {
      getSavedCards()
    }
  }, [user, callImmediate])

  const getSavedCards = () => {
    if (!user?.id) {
      return
    }
    callSavedCards()
  }

  const callSubscription = (...rest) => {
    if (!user?.id) {
      return
    }
    return callSubscriptions(...rest)
  }

  const callCreateClientSecret = (...rest) => {
    if (
      !user?.id ||
      clientSecretData?.createClientSecret?.clientSecret ||
      clientSecretDataWithRegister?.createClientSecret?.clientSecret
    ) {
      return
    }
    return callCreateClientSecretMutation(...rest)
  }

  const paypalCards =
    allSavedAccountsData?.data?.paypal?.map((item) => {
      return {
        brand: PAYMENT_TYPES.PAYPAL,
        cardId: item?.paypalId,
        ...item,
      }
    }) ?? []

  const creditCards = allSavedAccountsData?.data?.cards ?? []

  return {
    savedCards: data?.savedCards,
    callCreateClientSecretWithRegisterMutation,
    getSavedCards,
    deleteCard,
    deleteCardData,
    deleteCardErrors,
    callSubscription,
    subscriptionsLoading,
    subscriptionData,
    loading,
    called,
    updateSubscription,
    cancelSubscription,
    callCreateClientSecret,
    callPaypalAccounts,
    savedPaypalAccounts: paypalAccountsData?.data?.savedPaypalAccounts.map(
      (item) => {
        return {
          brand: PAYMENT_TYPES.PAYPAL,
          cardId: item?.paypalId,
          ...item,
        }
      }
    ),
    paypalAccountsData: {
      ...paypalAccountsData,
    },
    callAllSavedAccounts,
    allSavedAccountsData,
    clientSecret:
      clientSecretData?.createClientSecret?.clientSecret ||
      clientSecretDataWithRegister?.createClientSecret?.clientSecret,
    allCards: [...creditCards, ...paypalCards],
  }
}

export default useCreditCards
