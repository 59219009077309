import { LazyImage, VideoPlayer } from "@tmu/components/common"
import React, { useState, Fragment } from "react"
import Slider from "react-slick"
import { StyledMediaSlider } from "./index.styles"
import ReactPlayer from "react-player"

const MediaSlider = ({
  images = null,
  imageWidth = "760",
  imageHeight = "427.5",
  video = null,
  videoWidth = "720",
  videoHeight = "482",
  altName = "",
  className = "",
  useCustomVideoPlayer = false,
}) => {
  const [isVideoVisible, setVideoVisible] = useState(true)

  const handleSliderSwipe = (current, next) => {
    if (current === 0) setVideoVisible(false)
    if (next === 0) setVideoVisible(true)
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const placeholder = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjc3cHgiIGhlaWdodD0iMTg1cHgiIHZpZXdCb3g9IjAgMCAyNzcgMTg1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA2MS4yICg4OTY1MykgLSBodHRwczovL3NrZXRjaC5jb20gLS0+CiAgICA8dGl0bGU+bG9nby1zaGFwZS13aGl0ZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGxSdWxlPSJldmVub2RkIj4KICAgICAgICA8cGF0aCBmaWxsPSIjRUVFIiBkPSJNMCAwaDI3N3YxODVIMHoiIC8+CiAgICAgICAgPHBhdGgKICAgICAgICAgIGQ9Ik0xNjUgMTE3YTYgNiAwIDAwNi02Vjc1YTYgNiAwIDAwLTYtNmgtNTJhNiA2IDAgMDAtNiA2djM2YTYgNiAwIDAwNiA2aDUyem0wLTRoLTUyYy0xLjEwMyAwLTItLjg5Ny0yLTJWNzVjMC0xLjEwMy44OTctMiAyLTJoNTJjMS4xMDMgMCAyIC44OTcgMiAydjM2YzAgMS4xMDMtLjg5NyAyLTIgMnptLTQ0LTIzYTcgNyAwIDEwMC0xNCA3IDcgMCAwMDAgMTR6bTAtNGMtMS42NTQgMC0zLTEuMzQ2LTMtM3MxLjM0Ni0zIDMtMyAzIDEuMzQ2IDMgMy0xLjM0NiAzLTMgM3ptNDAuNSAyM2ExLjUgMS41IDAgMDAxLjUtMS41Vjk2YTMgMyAwIDAwLS44NzktMi4xMjFsLTExLTExYTMgMyAwIDAwLTQuMjQyIDBsLTExLjg4IDExLjg3OC0zLjg3OC0zLjg3OGEzIDMgMCAwMC00LjI0MiAwbC0xMSAxMWMtLjQ4NS40ODUtLjg3OSAxLjQzNS0uODc5IDIuMTIxdjMuNWExLjUgMS41IDAgMDAxLjUgMS41aDQ1em0tMi41LTRoLTQwdi0uNTg2bDEwLTEwIDYgNiAxNC0xNCAxMCAxMFYxMDV6IgogICAgICAgICAgZmlsbD0iIzMyNTE1OCIKICAgICAgICAgIGZpbGxSdWxlPSJub256ZXJvIgogICAgICAgICAgb3BhY2l0eT0iMC4xIgogICAgICAgIC8+CiAgICAgIDwvZz4KPC9zdmc+`

  return (
    <StyledMediaSlider hasVideo={video} className={className}>
      <Slider {...settings} beforeChange={handleSliderSwipe}>
        {useCustomVideoPlayer ? (
          <ReactPlayer
            isVideoVisible={isVideoVisible}
            url={video}
            width={videoWidth}
            height={videoHeight}
            controls={true}
            loop={true}
            light={false}
            playing={isVideoVisible}
            muted
            volume={1}
          />
        ) : (
          video && (
            <VideoPlayer
              isVideoVisible={isVideoVisible}
              url={video}
              width={videoWidth}
              height={videoHeight}
              muted
              volume={1}
            />
          )
        )}
        {images?.length > 0 ? (
          images.map(({ id, image, description }) => (
            <Fragment key={id}>
              <LazyImage
                src={image}
                altName={`slider-image-${id}`}
                width={imageWidth}
                height={imageHeight}
                fit="cover"
              />
              {description}
            </Fragment>
          ))
        ) : (
          <>
            <LazyImage
              src={placeholder}
              altName={`slider-image-${altName}`}
              width={imageWidth}
              height={imageHeight}
              fit="cover"
            />
          </>
        )}
      </Slider>
    </StyledMediaSlider>
  )
}

export default MediaSlider
