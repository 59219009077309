import React from 'react';
import styled, { keyframes } from 'styled-components';

// Animation for the dots
const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

// Wrapper for the loading dots
const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Styled dot component
const Dot = styled.div`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: white;
  border-radius: 50%;
  animation: ${bounce} 1.4s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }
  &:nth-child(2) {
    animation-delay: -0.16s;
  }
  &:nth-child(3) {
    animation-delay: 0s;
  }
`;

const LoadingDots = () => {
  return (
    <LoadingWrapper>
      <Dot />
      <Dot />
      <Dot />
    </LoadingWrapper>
  );
};

export default LoadingDots;
