import styled from "@emotion/styled"
import { Button } from "@tmu/components/common"
import { Link } from "gatsby-plugin-intl"
import { css } from "@emotion/react"

export const StyledCampaignMain = styled.div`
  width: 100%;
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: 4rem auto 0.5rem;
  padding: 4rem 1rem 0;
  display: block;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: minmax(0, 0.63fr) minmax(0, 0.37fr);
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    grid-gap: 2rem;
  }
`

export const StyledCongratsPopup = styled.div`
  position: absolute;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  background: white;
  padding: 1.25rem;
  display: flex;
  width: 30rem;
  margin-top: 3.375rem;
  top: 0;
  margin-left: -13.75rem;

  animation: 1s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;

  @keyframes fadeIn {
    99% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }

  .modal-content {
    padding-right: 3.125rem;
  }

  svg {
    font-size: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  h2 {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    margin-left: -10rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    margin-left: -5rem;
    width: 37.5rem;
    margin-top: 3.625rem;

    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
`

export const StyledCongratsMobile = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  height: 100vh;
  padding: 0.9375rem;
  .modal-content {
    span {
      h2 {
        margin-bottom: 0;
      }
    }
  }
  .sticky-bottom-bar {
    background: unset;
    hr {
      margin: 1.5rem 0;
    }
    button {
      background-color: ${(p) => p.theme.colors.blue};
      color: ${(p) => p.theme.colors.tableItemBorder};
    }
  }
  .congrats {
    margin-bottom: 0;
  }
  h2 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
    margin-top: 1rem;
  }
`

export const StyledVideoContainer = styled.div`
  max-width: 44.625rem;
  min-width: 44.625rem;
`

export const StyledCampaignCaption = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  height: auto !important;

  div:first-of-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
`

export const StyledTooltip = styled.div`
  #campaignDetails {
    position: absolute;
    margin-top: 0;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
    opacity: 1 !important;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      margin-top: -0.3125rem;
    }
  }
`

export const StyledCampaignLabel = styled(Link)`
  display: block;
  cursor: pointer;
  opacity: 0.55;
  margin-right: 1rem;
  svg,
  i {
    margin-right: 0.4375rem;
  }
  &:hover {
    opacity: 1;
  }
`

export const StyledCampaignStartsAt = styled.div`
  flex: 2 1 auto;
  text-align: right;
  opacity: 0.55;
  margin-bottom: 1rem;
`

export const StyledSupportEndedButton = styled(Button)`
  text-align: center;
  height: 4.0625rem;
  width: 100%;
  margin: 2.5rem 0 2.6875rem;
  font-size: 0.65rem;
  position: relative;
  cursor: pointer;
  white-space: pre-wrap;

  svg {
    display: none;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.65rem;
    height: 2.8rem;
    svg {
      display: none;
    }
  }
`

export const StyledSocialShare = styled.div`
  position: relative;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.75rem;
    font-weight: ${(p) => p.theme.fontWeights.black};
    font-family: ${(p) => p.theme.fontFamilies.regular};
    flex: 2;
    text-transform: uppercase;
    padding-top: 0.5rem;
  }
  svg {
    font-size: 2rem;
    height: 2rem;
    margin-left: 1.25rem;
    cursor: pointer;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 2rem;
    svg {
      font-size: 1.3rem;
      height: 1.3rem;
    }
  }
`

export const StyledWrapper = styled.div``

export const StyledContent = styled.div`
  padding: 1rem 1rem 0 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    padding: 1rem 1rem 0 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    padding: 2rem 2rem 0 0;
  }
`

export const StyledDate = styled.p`
  text-align: right;
  margin-bottom: 0 !important;
`

export const StyledPartnerHeader = styled.div`
  margin-bottom: 1rem;
  align-items: center;
  justify-items: start;
  font-size: 1.5rem;
`

export const StyledPartnerLogo = styled.img`
  object-fit: contain;
  border-radius: 0.25rem;
  height: 3rem;
  margin: 0 1rem 1rem 0;
`
export const StyledBrandCampaignLogo = styled.img`
  object-fit: cover;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  flex-shrink: 0;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    height: 3.375rem;
    width: 3.375rem;
  }
`

export const StyledUserImage = styled.img`
  object-fit: contain;
  border-radius: 50%;
  max-height: 3.5rem;
  max-width: 3.5rem;
  height: auto:
  width: auto;
  margin: 0 1rem 1rem 0;
`

export const StyledPartnerTitle = styled(Link)`
  display: flex;
`

export const StyledPartnerTitleText = styled.p`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.bold};
  font-size: 1.5rem;
`

export const StyledPartnerLink = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background: ${(p) => p.theme.colors.pacificBlue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.375rem;
  margin-bottom: 0.375rem;

  svg {
    color: ${(p) => p.theme.colors.white};
    height: 0.4375rem !important;
    width: 0.4375rem !important;
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`

export const StyledPartnerCampaignsLink = styled(Link)`
  align-items: center;
  padding: 0.25rem 1.125rem 0.125rem;
  max-width: 10rem;
  min-width: 10rem;
  width: 10rem;
  margin-left: 0;
  margin-right: auto;

  border: 0.0625rem solid ${(p) => p.theme.colors.gallery};
  box-sizing: border-box;
  border-radius: 6.25rem;
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  color: ${(p) => p.theme.colors.primaryBrandTextColor};
  font-size: 0.75rem;

  p {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ${(p) => p.theme.colors.racingGreen};

    span {
      font-weight: ${(p) => p.theme.fontWeights.bold};
    }
    svg {
      margin-left: 1rem;
    }
  }
`
export const StyledPartnerDetail = styled.div`
  padding-top: 1.75rem;
  border-top: 0.0625rem solid ${(p) => p.theme.colors.primaryTextColorLight};
  margin-bottom: 2.25rem;

  h4 {
    color: ${(p) => p.theme.colors.gondola};
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-bottom: 1.4375rem;
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.5rem;
    }
  }
`

export const StyledParagraph = styled.p`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
`

export const StyledCampaignSlice = styled.div`
  max-width: ${(p) => p.theme.breakpoints.wide};
  margin: auto;
  padding: 0 1rem;
  & > div {
    padding: 0;
  }
`

export const StyledEditButton = styled(Button)`
  text-align: center;
  height: 2.5rem;
  width: 100%;
  margin: 1rem 0 2.6875rem;
  font-size: 0.75rem;
  position: relative;
  cursor: pointer;
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-style: ${(p) => p.theme.fontStyles.normal};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
    height: 3.375rem;
  }
`

export const StyledPageWrapper = styled.div`
  .donation-grid-four {
    fieldset {
      grid-template-columns: repeat(12, 1fr) !important;
      grid-template-areas: "tier1 tier1 tier1 tier2 tier2 tier2 tier3 tier3 tier3 tier4 tier4 tier4" !important;
    }
  }
  .blue-text {
    color: ${(p) => p.theme.colors.pacificBlue};
  }

  .use-before span,
  .use-before p {
    font-size: 0.75rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 1rem;
    }

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1.5rem;
    }
  }
`
export const StyledDonationOptions = styled.div`
  margin-top: 1rem;
  word-break: break-word;
  .radio-select-item {
    background-color: ${(p) => p.theme.colors.white};
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    min-height: 5.063rem;
    padding-top: 1rem;
    border: 0.125rem;
    border-color: ${(p) => p.theme.colors.shareButtonColorZeroOpacity};
    border-style: solid;
    label {
      width: 100%;
    }
  }

  .radio-select-item-passive {
    background-color: ${(p) => p.theme.colors.darkGrey};
  }

  .radio-select-checked-item {
    border-color: ${(p) => p.theme.colors.shareButtonColorOneOpacity};
  }

  .radio-select-label {
    padding-left: 2rem;
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-style: ${(p) => p.theme.fontStyles.normal};
    font-weight: ${(p) => p.theme.fontWeights.liteBlack};
    font-size: 1.25rem;
  }

  .radio-select-input > span {
    margin-top: 0.8rem;
    margin-left: 1.2rem;
    border-color: ${(p) => p.theme.colors.darkGrey};
  }

  .radio-select-checked-input > span {
    border-color: ${(p) => p.theme.colors.shareButtonColorOneOpacity};
  }

  .radio-select-description {
    font-size: 0.75rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium};
    padding-left: 2rem;
    padding-top: 0.5rem;
    max-width: 90%;
    font-family: ${(p) => p.theme.fontFamilies.regular};
    line-height: 1rem;
  }

  .radio-label-align-left {
    float: left !important;
    padding-left: 2rem !important;
  }

  .radio-select-label-empty-description {
    position: absolute;
    margin-top: 0.65rem;
  }

  .radio-label-align-right {
    float: right !important;
    padding-right: 1rem !important;
    font-size: 1.25rem;

    @media screen and (max-width: ${(p) => p.theme.breakpoints.desktop}) {
      font-size: 0.75rem;
      padding-right: 0.5rem !important;
    }
  }

  //TODO: media query to be updated
  @media screen and (max-width: 767px) {
    margin: 0 0 1rem;
    .radio-select-label-empty-description {
      position: initial;
      margin: 0;
    }

    .radio-select-item {
      border: 1px solid ${(p) => p.theme.colors.concreteGrey};
    }

    .radio-select-checked-item {
      border: 1px solid ${(p) => p.theme.colors.pacificBlue} !important;
    }
  }
`

export const StyledSoldOutWarning = styled.div`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 80%;
    margin-bottom: 2rem;
  }
`

export const StyledPartnerSummaryMobile = styled.div`
  display: flex;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteMedium};
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1rem;
  }

  a:first-child {
    color: ${(p) =>
      p.isOfflineMerchant || p.isCorporateMerchant
        ? p.theme.colors.carrot
        : p.theme.colors.softGreen};
  }

  .carrot-text {
    color: ${(p) => p.theme.colors.carrot};
  }

  a {
    color: ${(p) => p.theme.colors.softGreen};
  }

  span {
    margin-left: 0.5rem;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 1.25rem;

    @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      line-height: 1.75rem;
    }
  }

  img {
    margin: 0 !important;
  }
`
export const StyledEventTimeInfo = styled.div`
  .caption {
    margin: 0;
    > bold {
      color: ${(p) => p.theme.colors.black};
    }
  }
`

export const StyledEventLocationInfo = styled.div`
  display: flex;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fontFamilies.regular};
  font-size: 0.75rem !important;
  font-weight: ${(p) => p.theme.fontWeights.black};
  color: ${(p) => p.theme.colors.softGreen};
  text-align: left;
  width: 91%;
  line-height: 1.25rem;
  word-break: break-word;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    width: 100%;
  }

  svg {
    margin-right: 0.5rem;
    color: ${(p) => p.theme.colors.black};
    font-size: 1.2rem;
    height: 1.2rem;
  }
`
export const StyledPACMultiplier = styled.div`
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  background: ${(p) => p.theme.colors.carrot};
  color: ${(p) => p.theme.colors.white};

  margin-left: 0.5rem;
  position: relative;
  border-radius: 0.25rem;
  min-width: 3.125rem;
  height: 1.5625rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.375rem;
  img {
    width: 0.75rem;
    height: 0.75rem;
    position: relative;
    top: 0.1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
    min-width: 3.0625rem;
    height: 1.5625rem;
    padding: 0.25rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      top: 0.125rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    padding: 0.125rem;
    img {
      width: 0.875rem;
      height: 0.875rem;
      top: 0.125rem;
    }
  }
`

export const StyledCampaignSubtitle = styled.div`
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
  .caption {
    margin: 0;
    line-height: 1.5;
    display: revert;
  }
  i {
    font-size: 1rem;
    color: ${(p) => p.theme.colors.disabled};
    margin-right: 0.5rem;
    ${(p) =>
      p.eventDate &&
      css`
        align-self: start;
        margin-top: 0.25rem;
      `}
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    i {
      font-size: 1.375rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    i {
      font-size: 1.75rem;
      margin-right: 1rem;
    }
  }
`

export const StyledDetailHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    display: unset;
    flex-direction: unset;
    justify-content: unset;
  }

  .detail-header {
    margin-top: unset;
  }
`

export const StyledHeaderAndShare = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    flex-direction: row;
  }

  > div:first-child {
    width: 92.5%;
  }

  > div:last-child {
    width: 7.5%;

    div:first-child {
      margin: 0;
    }
  }
`

export const StyledCampaignCreatedText = styled.div`
  font-size: 1.5rem;
  line-height: 2.125rem;
  margin: 0;
  color: ${(p) => p.theme.colors.pitchBlack};
  font-family: ${(p) => p.theme.fontFamilies.headline};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
`
export const StyledCampaignCreatedTextInfo = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.pitchBlack};

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.5rem !important;
  }
`
