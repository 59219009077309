import React from "react"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { Tooltip } from "react-tooltip"
import { useLocation } from "@reach/router"
import { LazyImage, Money, TruncatedText } from "@tmu/components/common"
import { CardStats } from "../CardStats"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import {
  StyledCardTitle,
  StyledCard,
  StyledCardInfo,
  StyledCardSubtitle,
  StyledImageBox,
  StyledPACMultiplier,
  StyledDoublePacsTooltip,
} from "./index.styles"

const { CLOUDFLARE_IMAGE_URL } = process.env

const CharityCard = ({
  title,
  subtitle,
  description,
  totalDonors,
  totalSupport,
  discount,
  inSupportOfTitle,
  inSupportOfLogo,
  fiscalCode,
  size,
  cardLink,
  goalAmount,
  fundedAmount,
  type,
  partnerName,
  partnerLogo,
  hasLogo,
  taxId,
  pacMultiplier,
  id,
  onClick,
  ...rest
}) => {
  const { formatMessage } = useIntl()
  const pacMultiplierNumber = pacMultiplier?.split(".")[0]
  const { isTablet, isDesktop, isWide } = getAllScreenTypes()
  const { pathname } = useLocation()

  const doublePacsTipText = formatMessage(
    {
      id: "doublePacs::listing::cardTooltip",
      defaultMessage:
        "You will get {pacMultiplierNumber} PACs for every EUR donated",
    },
    {
      pacMultiplierNumber: pacMultiplierNumber,
    }
  )

  const defaultSubtitle = (
    <>
      <TruncatedText numLines={1} className="charity-subtitle">
        <FormattedMessage
          id="dashboard::charityType::charity"
          defaultMessage="Charity"
        />
      </TruncatedText>
      <FormattedMessage
        id="typehead::partners::fiscalCode"
        defaultMessage="Fiscal Code"
      />
      : {taxId}
    </>
  )
  const PacMultiplier = () => {
    return Number(pacMultiplier) > 1 ? (
      <StyledPACMultiplier>
        <img
          className="pacs-pacs-image"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/white_pac.svg"}
          alt={`pacs-pacs-banner`}
          width={28}
          height={23}
        />
        {` X `}
        <Money value={pacMultiplier} currency={false} />
      </StyledPACMultiplier>
    ) : null
  }
  return (
    <StyledCard
      size={size}
      {...rest}
      onClick={onClick ? () => onClick() : () => navigate(cardLink)}
      data-testid={`charityCard${id}`}>
      <StyledImageBox hasLogo size={size}>
        <LazyImage
          src={partnerLogo}
          width={"auto"}
          height={174}
          altName={`card-image`}
          fit="contain"
        />
        {pacMultiplier && (
          <StyledDoublePacsTooltip
            data-tooltip-id={`charityCard${id}`}
            data-tooltip-content={doublePacsTipText}>
            <PacMultiplier />
            <Tooltip
              arrowColor="transparent"
              effect="solid"
              place="bottom"
              offset={{
                bottom: isWide ? 40 : 20,
                right: isWide ? 59 : 10,
              }}
              type="info"
              textColor="black"
              backgroundColor="white"
              id={`charityCard${id}`}
              className={`charityCard ${
                pathname.includes("campaigns") ||
                pathname.includes("user-space")
                  ? "charityCardListing"
                  : ""
              }`}
              delayShow={500}
            />
          </StyledDoublePacsTooltip>
        )}
      </StyledImageBox>
      <StyledCardInfo size={size}>
        <StyledCardTitle>
          <TruncatedText numLines={2}>{title}</TruncatedText>
        </StyledCardTitle>
        <StyledCardSubtitle className="caption">
          {subtitle || defaultSubtitle}
        </StyledCardSubtitle>
      </StyledCardInfo>
      <CardStats
        totalDonors={totalDonors}
        totalSupport={totalSupport}
        size={size}
      />
    </StyledCard>
  )
}

export default CharityCard
