import React, { Fragment, useState, useEffect, useRef } from "react"
import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAllOfferCategories } from "@tmu/hooks"
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import SEO from "@tmu/components/seo"
import "rc-slider/assets/index.css"
import queryString from "query-string"
import {
  NewCard,
  Spinner,
  Checkbox,
  FilterBar,
  LazyImage,
  CustomModal,
  CommonToggle,
  CountrySelectorFilter,
  OnlineOfflineMerchantFilter,
  SortSelector,
  CustomSlider,
} from "@tmu/components/common"
import {
  StyledWrapper,
  StyledListingFilters,
  StyledHeading,
  StyledContent,
  StyledListing,
  StyledActions,
  NoResults,
  StyledOffersBody,
  StyledFilterBar,
  StyledExplanationText,
  StyledMobileExplanationText,
  StyledHeadingTop,
  StyledHowToSpendPACWrapper,
  StyledHowToSpendPACMobile,
  StyledHowToSpendPACsDescription,
  StyledComingSoonToggle,
  StyledOnlineFilterWrapper,
  StyledRowGrid,
  StyledHowToWrapper,
  StyledHowToText,
  StyledHowToIcon,
  StyledSliderWrapper,
  StyledCheckboxTooltip,
} from "./index.styles"
import { CARDS_PER_PAGE, LIST_TYPES, OFFER_TYPE } from "@tmu/apollo/constants"
import { getQueryParams, getValueForLocale } from "@tmu/utils/string"
import { MERCHANT_TYPE } from "@tmu/components/common/OnlineOfflineMerchantFilter"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useOffers } from "@tmu/hooks"
import { Tooltip } from "react-tooltip"
import { isBrowser } from "@tmu/src/utils/auth"

const { CLOUDFLARE_IMAGE_URL } = process.env
const noResultImage =
  CLOUDFLARE_IMAGE_URL + "/static/assets/images/no_result.png"
const sortingTypes = [
  {
    id: "discount,-created",
    label: {
      id: "sorting::discountLow",
      defaultMessage: "PAC Ratio low > high",
    },
  },
  {
    id: "-discount,-created",
    label: {
      id: "sorting::discountHigh",
      defaultMessage: "PAC Ratio high > low",
    },
  },
  {
    id: "-created",
    label: {
      id: "sorting::dateNew",
      defaultMessage: "Date: new > old",
    },
  },
  {
    id: "created",
    label: {
      id: "sorting::dateOld",
      defaultMessage: "Date: old > new",
    },
  },
]
const OfferListing = () => {
  const [firstPageLoad, setFirstPageLoad] = useState(false)
  const { locale, defaultLocale, formatMessage } = useIntl()

  const [exURL, setExUrl] = useState()
  const [fakeURL, setFakeURL] = useState()
  const params = getQueryParams(fakeURL)

  const filterBarRef = useRef()
  const loadRef = useRef()
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const [selectedCountry, setSelectedCountry] = useState()
  const [selectedCity, setSelectedCity] = useState()
  const [selectedSortBy, setSelectedSortBy] = useState("-isFeatured,-created")
  const [isCategoriesFetched, setIsCategoriesFetched] = useState(false)
  const [listType, setListType] = useState(params?.view || LIST_TYPES.TILE)
  const [isExplanationVisible, setExplanationVisible] = useState(false)
  const [isOffersFilter, setOffersFilter] = useState(params?.offer === "true")
  const [isComingSoonFilter, setComingSoonFilter] = useState(
    params?.comingsoon === "true"
  )
  const [isStoresFilter, setStoresFilter] = useState(params?.store === "true")
  const [isExternalStoresFilter, setIsExternalStoresFilter] = useState(
    params?.internalStore !== "false"
  )
  const [isInternalStoresFilter, setIsInternalStoresFilter] = useState(
    params?.internalStore !== "false"
  )
  const [isModalHowToVisible, setModalHowToVisible] = useState(false)
  const [isBannerHowToVisible, setBannerHowToVisible] = useState(false)
  const [selectedStores, setSelectedStores] = useState([])
  const [loadMoreAfter, setLoadMoreAfter] = useState(false)
  const [merchantType, setMerchantType] = useState(MERCHANT_TYPE.ONLINE)
  const [minMaxDiscount, setMinMaxDiscount] = useState([0, 100])
  const [minMaxPrice, setMinMaxPrice] = useState([])
  const [maxPrice, setMaxPrice] = useState()
  const [offerVariables, setOfferVariables] = useState({})
  const [selectedCategory, setSelectedCategory] = useState()
  const [filterLoading, setFilterLoading] = useState(false)

  const [exMinMaxDiscount, setExMinMaxDiscount] = useState([0, 100])

  const [triggerChangeURL, setTriggerChangeURL] = useState(false)

  useEffect(() => {
    setExUrl(isBrowser ? window?.location?.search : "")
  }, [triggerChangeURL])

  const {
    callOffers,
    data,
    refetch,
    loading,
    called,
    handleLoadMore,
    pageInfo,
  } = useOffers()
  const isOnlineMerchant = merchantType === MERCHANT_TYPE.ONLINE

  const { isTablet, isDesktop, isWide } = getAllScreenTypes()

  const perPage = CARDS_PER_PAGE

  const { allOfferCategories } = useAllOfferCategories()
  useEffect(() => {
    setStoresFilter(params?.store === "true")
    setOffersFilter(params?.offer === "true")
  }, [params?.store, params?.offer, params?.comingsoon])

  useEffect(() => {
    if (params?.store === "true" || params?.offer === "true") {
      const isMinMaxDiscountArray =
        Array.isArray(minMaxDiscount) && minMaxDiscount?.length > 1
      const isMinMaxPriceArray =
        Array.isArray(minMaxPrice) && minMaxPrice?.length > 1
      const offerTypeIn = []
      if (isOnlineMerchant) {
        if (isExternalStoresFilter) {
          offerTypeIn.push("ONLINE")
        }
        if (isInternalStoresFilter) {
          offerTypeIn.push("INTERNAL")
        }
      } else {
        offerTypeIn.push("OFFLINE")
      }

      const offerOrigin = []

      if (isOffersFilter) {
        offerOrigin.push("offers")
      }

      if (isStoresFilter) {
        offerOrigin.push("stores")
      }

      const categoryChildrenIdList =
        selectedCategory?.children
          ?.filter((item) => item?.id !== "-11" && item?.id !== "-1")
          .map((item) => item.id) || []

      const vars = {
        first: perPage,
        orderBy: selectedSortBy,
        availableCountries: selectedCountry,
        availableCities: selectedCity && [selectedCity],
        categories: [...selectedCategoryIds, ...categoryChildrenIdList],
        store: selectedStores,
        isPublic: true,
        badge: ["NO_BADGE"],
        offerType_In: offerTypeIn?.length ? offerTypeIn : null,
        maxPacDiscount_Lte:
          (isMinMaxDiscountArray && minMaxDiscount?.[1]) || null,
        maxPacDiscount_Gte:
          (isMinMaxDiscountArray && minMaxDiscount?.[0]) || null,
        // maxPrice_Lte: (isMinMaxPriceArray && minMaxPrice?.[1]) || null,
        // maxPrice_Gte: (isMinMaxPriceArray && minMaxPrice?.[0]) || null,
        offerOrigin: offerOrigin?.length ? offerOrigin : null,
      }

      if (!isOnlineMerchant) {
        delete vars.maxPrice_Lte
        delete vars.maxPrice_Gte
      }

      setOfferVariables(vars)
    }
  }, [
    isStoresFilter,
    isOffersFilter,
    isExternalStoresFilter,
    isInternalStoresFilter,
    isComingSoonFilter,
    selectedSortBy,
    selectedCategoryIds,
    selectedStores,
    perPage,
    selectedCountry,
    selectedCity,
    isOnlineMerchant,
    minMaxDiscount,
    minMaxPrice,
    merchantType,
  ])

  useEffect(() => {
    if (!offerVariables?.first) {
      return
    }

    setFilterLoading(true)

    if (called) {
      refetch(offerVariables).finally(() => setFilterLoading(false))
    } else {
      callOffers({ variables: offerVariables }).finally(() =>
        setFilterLoading(false)
      )
    }
  }, [offerVariables])

  useEffect(() => {
    setBannerHowToVisible(true)
    if (params?.country) {
      setSelectedCountry(params?.country)
    }

    if (params?.city) {
      setSelectedCity(decodeURIComponent(params?.city))
    }

    if (params?.orderBy && params?.orderBy?.length) {
      const orderData = Array.isArray(params?.orderBy)
        ? params?.orderBy?.join(",")
        : params?.orderBy
      setSelectedSortBy(orderData)
    }
    if (params?.stores && params?.stores?.length) {
      const storeData = Array.isArray(params?.stores)
        ? params?.stores?.join(",")
        : params?.stores
      setSelectedStores(storeData)

      const tempData = Array.isArray(params?.stores)
        ? params?.stores
        : [params?.stores]
      setSelectedStores(tempData)
    }
    if (params?.merchantType && params?.merchantType?.length) {
      const isOnline = Number(params?.merchantType)
      setMerchantType(isOnline)
    }

    if (params?.externalStore && params?.externalStore?.length) {
      setIsExternalStoresFilter(params.externalStore !== "false")
    }
    if (params?.internalStore && params?.internalStore?.length) {
      setIsInternalStoresFilter(params.internalStore !== "false")
    }
  }, [])

  useEffect(() => {
    if (
      !isCategoriesFetched &&
      allOfferCategories &&
      allOfferCategories?.length
    ) {
      setIsCategoriesFetched(true)

      if (params?.offerCategories && params?.offerCategories?.length) {
        const categories = params?.offerCategories?.split("|")
        setSelectedCategories(categories)
        const categoryIds = allOfferCategories
          .filter((t) => categories.indexOf(t.slug) !== -1)
          .map((t) => t.id)

        setSelectedCategoryIds(categoryIds)
      }
    }
  }, [allOfferCategories])

  const changeUrlState = (url, navigate = false) => {
    if (isTablet || navigate) {
      window?.history?.pushState({}, null, url)
    }
    setFakeURL(url)
  }

  const handleFilterSubmit = () => {
    setMinMaxDiscount(exMinMaxDiscount)
    window?.history?.pushState({}, null, fakeURL)
    setTriggerChangeURL(!triggerChangeURL)
  }

  const closeButtonExtraAction = () => {
    setFakeURL(exURL)
  }

  const handleCategorySelect = (id, slug, selectedCategoryItem) => {
    let categoryValue = [...selectedCategories, slug]

    if (selectedCategories.indexOf(slug) > -1) {
      categoryValue = selectedCategories.filter((c) => c !== slug)
      setSelectedCategories(categoryValue)
    } else setSelectedCategories([...selectedCategories, slug])

    if (categoryValue?.length) {
      delete params?.offerCategories

      const parsedData = categoryValue?.join("|")

      navigateWithParams({
        ...params,
        offerCategories: parsedData,
      })

      const categoryIds = allOfferCategories
        .filter((t) => categoryValue.indexOf(t.slug) !== -1)
        .map((t) => t.id)

      setSelectedCategoryIds(categoryIds)
    } else {
      delete params?.offerCategories

      navigateWithParams({
        ...params,
      })

      setSelectedCategoryIds([])
    }
  }

  const handleCategorySelectSingle = (id, slug, selectedCategoryItem) => {
    if (!id) {
      delete params?.offerCategories
      setSelectedCategoryIds([])
      setSelectedCategories([])
      setSelectedCategory(null)
    } else {
      params["offerCategories"] = [slug]
      setSelectedCategoryIds([id])
      setSelectedCategories([slug])
      setSelectedCategory(selectedCategoryItem)
    }

    navigateWithParams({
      ...params,
    })
  }

  const handleCountryChange = (selectedRegion) => {
    if (selectedRegion?.value === -1) {
      delete params["city"]
      delete params["country"]
    } else {
      const isCity = selectedRegion?.node?.country?.code
      if (!isCity) {
        delete params["city"]
        params["country"] = selectedRegion?.value ?? selectedRegion?.id
      } else {
        params["city"] = selectedRegion?.value
        params["country"] = selectedRegion?.node?.country?.code
      }
    }

    navigateWithParams(
      {
        ...params,
      },
      true
    )

    setSelectedCountry(params?.country)
    setSelectedCity(params?.city)
  }

  const handleSortingChange = (orderBy) => {
    navigateWithParams({
      ...params,
      orderBy: orderBy?.value,
    })
    setSelectedSortBy(orderBy?.value)
  }

  const navigateWithParams = (newParams, navigate = false) => {
    const updatedParams = queryString.stringify(
      { ...newParams },
      { arrayFormat: "comma" }
    )
    changeUrlState("?" + updatedParams, navigate)
  }

  const handleShowAll = () => {
    delete params?.campaignCategories
    delete params?.offerCategories
    setSelectedCategories([])
    setSelectedCategoryIds([])
    navigateWithParams({ ...params })
  }

  const handeListType = (listType) => {
    setListType(listType)
    navigateWithParams({
      ...params,
      view: listType,
    })
  }

  const handleTypeChange = (newParam, navigate = false) => {
    navigateWithParams(
      {
        ...params,
        ...newParam,
      },
      navigate
    )
  }

  useEffect(() => {
    const maxPrice = data?.maxPrice
    if (data && maxPrice && !isNaN(maxPrice)) {
      setMaxPrice(Number(maxPrice))
    }
  }, [data])

  const howToSpendPACsTitle = (
    <FormattedMessage
      id="productListing::howToSpendPacs::title"
      defaultMessage="How to spend PACs"
    />
  )

  const howDonationDiscountWorksTitle = (
    <FormattedMessage
      id="productListing::howDonationDiscountWorksTitle::title"
      defaultMessage="How donation discounts work"
    />
  )

  const howToTitle =
    isOnlineMerchant !== false
      ? howToSpendPACsTitle
      : howDonationDiscountWorksTitle

  const howToSpendPACsDescription = (
    <StyledHowToSpendPACsDescription>
      <FormattedMessage
        id="productListing::howToSpendPacs::text1"
        defaultMessage="Numbers show the amount of PAC Offer -- how much % of the price you can pay with your PACS."
        values={{
          span: (...chunks) => (
            <span>
              {chunks.map((chunk, i) => (
                <Fragment key={i}>{chunk}</Fragment>
              ))}
            </span>
          ),
        }}>
        {(...chunks) => (
          <p>
            {chunks.map((chunk, i) => (
              <Fragment key={i}>{chunk}</Fragment>
            ))}
          </p>
        )}
      </FormattedMessage>
      <FormattedMessage
        id="productListing::howToSpendPacs::text2"
        defaultMessage="Choose an offer to go the merchant’s store and put the offer in the merchant’s cart. PACs will be applied in the merchant’s cart."
        tagName="p"
      />
      <Link to={`/pacs`} aria-label="pacs" className="pacs-link">
        <FormattedMessage
          id="pacs::hero::title"
          defaultMessage="What are PACs?"
        />
      </Link>
    </StyledHowToSpendPACsDescription>
  )

  const howDiscountWorksDescription = (
    <StyledHowToSpendPACsDescription>
      <FormattedMessage
        id="productListing::howDiscountWorksDescription::text1"
        defaultMessage="Visiting off-line store, you have a discount. The <carrot>% number</carrot> shows how much of the price you can donate to a charity or pay with your PACs"
        values={{
          carrot: (...chunks) => (
            <span className="how-to-number">
              {chunks.map((chunk, i) => (
                <Fragment key={i}>{chunk}</Fragment>
              ))}
            </span>
          ),
        }}>
        {(...chunks) => (
          <p>
            {chunks.map((chunk, i) => (
              <Fragment key={i}>{chunk}</Fragment>
            ))}
          </p>
        )}
      </FormattedMessage>
    </StyledHowToSpendPACsDescription>
  )

  const howToDescription =
    isOnlineMerchant !== false
      ? howToSpendPACsDescription
      : howDiscountWorksDescription

  const handleStoreFilterChange = (values) => {
    const idArr = values?.map((item) => item?.value)

    navigateWithParams({
      ...params,
      stores: idArr,
    })

    setSelectedStores(idArr)
  }

  const howToSpendPACWrapper = (
    <StyledHowToSpendPACWrapper
      onMouseEnter={() => setExplanationVisible(true)}
      onMouseLeave={() => setExplanationVisible(false)}>
      <StyledHowToIcon>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </StyledHowToIcon>
      <StyledHowToText>{howToTitle}</StyledHowToText>
      {isExplanationVisible && (
        <StyledExplanationText>
          <div>
            {isOnlineMerchant !== false && (
              <div className="images">
                <LazyImage
                  className="merchant-banner-image"
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    "/static/assets/images/blank_cards/Card_1_1024.png"
                  }
                  altName={`donors-default-banner`}
                  fit="cover"
                />
                <div className="spacing" />
                <LazyImage
                  className="merchant-banner-image"
                  src={
                    CLOUDFLARE_IMAGE_URL +
                    "/static/assets/images/blank_cards/Card_2_1024.png"
                  }
                  altName={`donors-default-banner`}
                  fit="cover"
                />
              </div>
            )}
            {howToDescription}
          </div>
        </StyledExplanationText>
      )}
    </StyledHowToSpendPACWrapper>
  )

  //Initialize the intersection observer API
  useEffect(() => {
    if (!loadRef?.current) {
      return
    }
    let options = {
      threshold: 1,
    }
    const observer = new IntersectionObserver(handleObserver, options)
    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [loadRef?.current, pageInfo])

  // Handle intersection with load more
  const handleObserver = (entities) => {
    const target = entities[0]
    if (target.isIntersecting) {
      setLoadMoreAfter(true)
    }
  }

  const handleMerchantTypeChange = (merchantTypeData) => {
    setMerchantType(merchantTypeData)
    resetFilters({ merchantTypeData }, true)
  }

  // Handle loading more
  useEffect(() => {
    if (loadMoreAfter && pageInfo?.hasNextPage) {
      handleLoadMore(offerVariables)
      setLoadMoreAfter(false)
    }
  }, [loadMoreAfter, pageInfo?.hasNextPage])

  const showNoResult = !loading && called

  const sortOptionsData = sortingTypes?.map(({ id, label }) => ({
    value: id,
    label: formatMessage(label),
  }))

  const externalStoresTooltipText = formatMessage({
    id: "offerListing::filters::externalStoresTooltip",
    defaultMessage:
      "When purchasing, you will be redirected to a merchant's website",
  })

  const internalStoresTooltipText = formatMessage({
    id: "offerListing::filters::internalStoresTooltip",
    defaultMessage: "Shop inside trustmeup.com",
  })

  useEffect(() => {
    setFirstPageLoad(true)
  }, [])

  const resetFilters = ({ merchantTypeData }, navigate = false) => {
    const value = merchantTypeData ? false : true
    handleTypeChange(
      {
        offer: value,
        store: true,
        //comingsoon: false,
        stores: [],
        merchantType: merchantTypeData,
        externalStore: value,
        internalStore: value,
      },
      navigate
    )
    setOffersFilter(value)
    setStoresFilter(true)
    setComingSoonFilter(false)
    setSelectedStores([])
    setIsExternalStoresFilter(value)
    setIsInternalStoresFilter(value)
  }

  const handleSliderChange = (value) => {
    if (isTablet) setMinMaxDiscount(value)
    setExMinMaxDiscount(value)
  }

  const handlePriceSliderChange = (value) => {
    setMinMaxPrice(value)
  }

  if (!firstPageLoad) {
    return <Spinner />
  }

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage({
          id: "offerListing::title::pacStores",
          defaultMessage: "Browse and Shop",
        })}
        description={formatMessage({
          id: "seo::offerListing::description",
          defaultMessage:
            "Use your discounts on the various stores in our commercial gallery. Start your shopping now.",
        })}
      />
      <StyledWrapper id="offers">
        <StyledHeading>
          <div>
            <StyledHeadingTop>
              <FormattedMessage
                id="offerListing::title::pacStores"
                defaultMessage="PAC Stores"
                tagName="h3"
              />
              <div>
                <Link to={`/join/merchants/`} aria-label="merchants">
                  <FormattedMessage
                    id="productListing::joinAsMerchant"
                    defaultMessage="JOIN AS MERCHANT"
                    tagName="h5"
                  />
                </Link>
              </div>
            </StyledHeadingTop>
            {!isTablet && (
              <StyledOnlineFilterWrapper>
                <OnlineOfflineMerchantFilter
                  onChange={handleMerchantTypeChange}
                  isOnlineMerchant={isOnlineMerchant}
                />
              </StyledOnlineFilterWrapper>
            )}
            <StyledFilterBar>
              <FilterBar
                disabled={filterLoading}
                handleSort={handleSortingChange}
                handleSelectCountry={handleCountryChange}
                selectedCountry={selectedCountry}
                selectedCity={selectedCity}
                handleListType={handeListType}
                sortOptions={sortingTypes}
                defaultSortValue={selectedSortBy}
                categories={allOfferCategories}
                selected={selectedCategories}
                onSelect={handleCategorySelect}
                onSingleCategorySelect={handleCategorySelectSingle}
                handleShowAll={handleShowAll}
                ref={filterBarRef}
                isStoresFilter={isStoresFilter}
                setStoresFilter={setStoresFilter}
                isOffersFilter={isOffersFilter}
                setOffersFilter={setOffersFilter}
                isComingSoonFilter={isComingSoonFilter}
                setComingSoonFilter={setComingSoonFilter}
                handleTypeChange={handleTypeChange}
                isEventsFilter="false"
                searchFieldName="allOffers"
                isOfferList={true}
                handleStoreFilterChange={handleStoreFilterChange}
                storeFilterDefaultValue={selectedStores}
                onMerchantTypeChange={handleMerchantTypeChange}
                isOnlineMerchant={isOnlineMerchant}
                merchantType={merchantType}
                withTitles={true}
                onSliderChange={handleSliderChange}
                sliderDefaultValue={exMinMaxDiscount}
                handlePriceSliderChange={handlePriceSliderChange}
                minMaxPrice={minMaxPrice}
                setIsInternalStoresFilter={setIsInternalStoresFilter}
                setIsExternalStoresFilter={setIsExternalStoresFilter}
                isInternalStoresFilter={isInternalStoresFilter}
                isExternalStoresFilter={isExternalStoresFilter}
                showSubmit={true}
                handleSubmit={handleFilterSubmit}
                closeButtonExtraAction={closeButtonExtraAction}
                filters={{
                  category: isTablet,
                  store: true,
                  onlineOflineMerchant: isTablet,
                  country: !isTablet,
                  discountSlider: true,
                  priceSlider: true,
                  comingSoon: false,
                  internalExternalStores: true,
                  storeTypes: false,
                }}
                queryFilters={offerVariables}
              />
            </StyledFilterBar>
            {!isTablet && isBannerHowToVisible && (
              <StyledHowToSpendPACMobile>
                <div onClick={() => setModalHowToVisible(true)}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                  {howToTitle}
                </div>
                <FontAwesomeIcon
                  icon={faXmark}
                  onClick={() => setBannerHowToVisible(false)}
                />
              </StyledHowToSpendPACMobile>
            )}
          </div>
        </StyledHeading>
        <StyledOffersBody>
          <div>
            <StyledListingFilters>
              {isTablet && (
                <div className="country-filter">
                  <CountrySelectorFilter
                    isOfferList={true}
                    handleSelect={handleCountryChange}
                    activeCountry={selectedCountry}
                    activeCity={selectedCity}
                    isDropdown={false}
                    searchable={true}
                  />
                </div>
              )}
              {isOnlineMerchant !== false && (
                <>
                  <div>
                    <div
                      data-tooltip-content={externalStoresTooltipText}
                      data-tooltip-id="external-stores-tooltip">
                      <Checkbox
                        className="filter-checkbox"
                        label={
                          <FormattedMessage
                            id="offerListing::filters::externalStores"
                            defaultMessage="External Stores"
                          />
                        }
                        isChecked={isExternalStoresFilter}
                        onChange={() => {
                          const newValue = !isExternalStoresFilter
                          handleTypeChange({
                            externalStore: newValue,
                          })
                          setIsExternalStoresFilter(newValue)
                        }}
                      />
                      <StyledCheckboxTooltip>
                        <Tooltip
                          arrowColor="transparent"
                          effect="solid"
                          place="bottom"
                          type="info"
                          textColor="black"
                          backgroundColor="white"
                          id="external-stores-tooltip"
                          className="external-stores-tooltip"
                          delayShow={500}
                        />
                      </StyledCheckboxTooltip>
                    </div>
                    <div
                      data-tooltip-content={internalStoresTooltipText}
                      data-tooltip-id="internal-stores-tooltip">
                      <Checkbox
                        className="filter-checkbox"
                        label={
                          <FormattedMessage
                            id="offerListing::filters::internalStores"
                            defaultMessage="Internal Stores"
                          />
                        }
                        isChecked={isInternalStoresFilter}
                        onChange={() => {
                          const newValue = !isInternalStoresFilter
                          handleTypeChange({
                            internalStore: newValue,
                          })
                          setIsInternalStoresFilter(newValue)
                        }}
                      />
                      <StyledCheckboxTooltip>
                        <Tooltip
                          arrowColor="transparent"
                          effect="solid"
                          place="bottom"
                          type="info"
                          textColor="black"
                          backgroundColor="white"
                          id="internal-stores-tooltip"
                          className="internal-stores-tooltip"
                          delayShow={500}
                        />
                      </StyledCheckboxTooltip>
                    </div>
                  </div>
                  {false && (
                    <StyledComingSoonToggle>
                      <FormattedMessage
                        id="offerListing::filters::comingSoon"
                        defaultMessage="Show coming soon"
                      />
                      <CommonToggle
                        color="carrot"
                        defaultChecked={isComingSoonFilter}
                        handleChange={() => {
                          handleTypeChange({ comingsoon: !isComingSoonFilter })
                          setComingSoonFilter(!isComingSoonFilter)
                        }}
                      />
                    </StyledComingSoonToggle>
                  )}
                </>
              )}

              {isTablet && (
                <>
                  <FormattedMessage
                    id="listing::filters::donationDiscount"
                    defaultMessage="Donation Discount"
                    tagName="p"
                  />
                  <StyledSliderWrapper>
                    <CustomSlider
                      onChange={handleSliderChange}
                      defaultValue={minMaxDiscount}
                      customRightLabel={"100%"}
                      isPercent={true}
                      max={100}
                    />
                  </StyledSliderWrapper>
                  {false &&
                    isOnlineMerchant !== false &&
                    !offerVariables?.offerType_In?.includes("ONLINE") &&
                    offerVariables?.offerType_In?.includes("INTERNAL") && (
                      <>
                        <FormattedMessage
                          id="listing::filters::price"
                          defaultMessage="Price"
                          tagName="p"
                        />
                        <StyledSliderWrapper className="price-slider">
                          <CustomSlider
                            onChange={handlePriceSliderChange}
                            defaultValue={minMaxPrice}
                            max={maxPrice}
                            customRightLabel={
                              <FormattedMessage
                                id="listing::filters::priceRightLabel"
                                defaultMessage="All Prices"
                              />
                            }
                          />
                        </StyledSliderWrapper>
                      </>
                    )}
                </>
              )}
            </StyledListingFilters>
            <StyledContent>
              {isTablet && (
                <StyledRowGrid>
                  <StyledHowToWrapper>
                    {isTablet && howToSpendPACWrapper}
                  </StyledHowToWrapper>
                  <SortSelector
                    defaultValue={selectedSortBy}
                    options={sortOptionsData}
                    handleSort={handleSortingChange}
                  />
                </StyledRowGrid>
              )}
              {loading || filterLoading ? (
                <Spinner />
              ) : (
                <StyledListing>
                  {data?.edges?.length > 0
                    ? data?.edges?.map(({ node: offer }) => {
                        return (
                          <NewCard
                            variant={
                              offer?.isDefaultOffer
                                ? offer?.offerType === OFFER_TYPE.OFFLINE
                                  ? "offlineStore"
                                  : "store"
                                : offer?.offerType === OFFER_TYPE.INTERNAL
                                ? "service"
                                : "offer"
                            }
                            id={offer?.id}
                            key={offer?.id}
                            imageSrc={offer?.image}
                            title={getValueForLocale(
                              offer,
                              "name",
                              locale,
                              defaultLocale
                            )}
                            description={getValueForLocale(
                              offer,
                              "description",
                              locale,
                              defaultLocale
                            )}
                            maxPacDiscount={
                              offer?.isDefaultOffer &&
                              offer?.offerType !== OFFER_TYPE.OFFLINE
                                ? offer?.store?.maxPacDiscountInOffers
                                : offer?.maxPacDiscount
                            }
                            minPrice={offer?.minPrice}
                            maxPrice={offer?.maxPrice}
                            maxPacDiscountInOffers={
                              offer?.store?.maxPacDiscountInOffers
                            }
                            size={!isDesktop ? LIST_TYPES.LIST : listType}
                            cardLink={
                              offer?.isDefaultOffer
                                ? `/offers/store/${offer?.store?.slug}?offer=true`
                                : offer?.offerType === OFFER_TYPE.INTERNAL
                                ? `/services/${offer?.slug}`
                                : `/offers/detail/${offer?.slug}`
                            }
                            storeLogo={getValueForLocale(
                              offer?.store,
                              "logo",
                              locale,
                              defaultLocale
                            )}
                            storeName={
                              offer?.store?.displayName || offer?.store?.name
                            }
                            isComingSoon={offer?.store?.badge?.includes(
                              "COMING_SOON"
                            )}
                          />
                        )
                      })
                    : showNoResult && (
                        <NoResults>
                          <LazyImage
                            src={noResultImage}
                            altName={`no-result-image`}
                            height={isWide ? 572 : isTablet ? 372 : 278}
                            fit="cover"
                          />
                          <FormattedMessage
                            id="campaignListing::filters::noResult"
                            defaultMessage="We couldn’t find any results ("
                            tagName="h6"
                          />
                          <FormattedMessage
                            id="campaignListing::filters::tryFilters"
                            defaultMessage="Try to change filters"
                            tagName="p"
                          />
                        </NoResults>
                      )}
                </StyledListing>
              )}
              <StyledActions ref={loadRef}>
                {pageInfo?.hasNextPage && <Spinner condensed />}
              </StyledActions>
            </StyledContent>
            {isModalHowToVisible && !isTablet && (
              <CustomModal
                isModalOpen={isModalHowToVisible}
                style={{ background: "rgba(244, 244, 242, 1)", padding: "0" }}
                headerStyle={{
                  background: "rgba(244, 244, 242, 1)",
                  paddingLeft: "1rem",
                }}
                header={howToTitle}
                isCloseIcon={true}
                cancelButtonAction={() => setModalHowToVisible(false)}
                isMobile={!isTablet}
                stickyHeader
                children={
                  <StyledMobileExplanationText>
                    {isOnlineMerchant !== false && (
                      <>
                        <LazyImage
                          className="merchant-banner-image"
                          src={
                            CLOUDFLARE_IMAGE_URL +
                            "/static/assets/images/blank_cards/Card_1_360.png"
                          }
                          altName={`donors-default-banner`}
                          fit="cover"
                        />
                        <LazyImage
                          className="merchant-banner-image"
                          src={
                            CLOUDFLARE_IMAGE_URL +
                            "/static/assets/images/blank_cards/Card-2.png"
                          }
                          altName={`donors-default-banner`}
                          fit="cover"
                        />
                      </>
                    )}
                    {howToDescription}
                  </StyledMobileExplanationText>
                }
              />
            )}
          </div>
        </StyledOffersBody>
      </StyledWrapper>
    </>
  )
}

export default OfferListing
