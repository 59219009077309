import { MAX_DAYS_END_EVENT } from "@tmu/src/apollo/constants"
import { string, boolean, number, date, array, object } from "yup"

const urlRegex =
  /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/

export const requiredString = ({ formatMessage }) =>
  string()
    .min(1)
    .max(128) // Adds maximum length of 255 characters
    .required(
      formatMessage({
        id: "forms::requiredField",
        defaultMessage: "This field is required",
      })
    )
    .nullable()

export const requiredNumber = ({ formatMessage }) =>
  number()
    .min(1)
    .required(
      formatMessage({
        id: "forms::requiredField",
        defaultMessage: "This field is required",
      })
    )

export const firstName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::fullNameLengthError",
          defaultMessage:
            "The first name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .trim()
    .required(
      formatMessage({
        id: "forms::fullNameRequiredError",
        defaultMessage: "Enter your first name",
      })
    )

export const campanyName = ({ formatMessage }) =>
  string()
    .trim()
    .required(
      formatMessage({
        id: "forms::yourCompanyError",
        defaultMessage: "Enter your company name",
      })
    )

export const developersName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::developerNameLengthError",
          defaultMessage: "Name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::developerNameRequiredError",
        defaultMessage: "Enter the developer's name",
      })
    )
export const fiscalCode = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::fiscalCodeRequired",
      defaultMessage: "Enter your fiscal code",
    })
  )

export const vat = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::vatRequired",
      defaultMessage: "Enter your VAT",
    })
  )

export const offerName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::offerNameLengthError",
          defaultMessage:
            "The offer name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::offerNameRequiredError",
        defaultMessage: "The offer name is required.",
      })
    )

export const contactName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::contactNameLengthError",
          defaultMessage:
            "The contact name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::contactNameRequiredError",
        defaultMessage: "The contact name is required.",
      })
    )

export const merchantName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::merchantNameLengthError",
          defaultMessage:
            "The merchant name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::merchantNameRequiredError",
        defaultMessage: "The merchant name is required.",
      })
    )

export const legalName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::legalNameLengthError",
          defaultMessage: "Legal name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::legalNameRequiredError",
        defaultMessage: "Legal name is required.",
      })
    )

export const referralName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::referralNameLengthError",
          defaultMessage:
            "Referral name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .max(
      256,
      formatMessage(
        {
          id: "forms::referralNameLengthErrorShort",
          defaultMessage:
            "Referral name must be shorter than or equal to { value } letters.",
        },
        {
          value: 256,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::referralNameRequiredError",
        defaultMessage: "Referral name is required.",
      })
    )
export const serviceName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::serviceNameLengthError",
          defaultMessage: "Service name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .max(
      256,
      formatMessage(
        {
          id: "forms::serviceNameLengthErrorShort",
          defaultMessage:
            "Service name must be shorter than or equal to { value } letters.",
        },
        {
          value: 256,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::serviceNameRequiredError",
        defaultMessage: "Service name is required.",
      })
    )

export const displayName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::displayNameLengthError",
          defaultMessage: "Last name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .trim()
    .required(
      formatMessage({
        id: "forms::displayNameRequiredError",
        defaultMessage: "Enter your last name",
      })
    )

export const organizationName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::organizationNameLengthError",
          defaultMessage:
            "The organization name must be longer than {value} letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::organizationNameRequiredError",
        defaultMessage: "The organization name is required.",
      })
    )

export const maxReferralNumber = ({ formatMessage }) =>
  number()
    .when("isUnlimited", {
      is: false,
      then: () =>
        number()
          .required(
            formatMessage({
              id: "forms::fieldRequiredError",
              defaultMessage: "This field is required.",
            })
          )
          .integer(
            formatMessage({
              id: "forms::referralNumberIntegerError",
              defaultMessage: "Must be a valid number.",
            })
          )
          .max(
            9999,
            formatMessage({
              id: "forms::referralNumberMaxError",
              defaultMessage: "Amount must be less than or equal to 9999.",
            })
          ),
      otherwise: () => {},
    })
    .nullable()
    .default(undefined)

export const recurringDonationAmount1 = ({ formatMessage, minValue }) =>
  number()
    .when("enableRecurringCampaign", {
      is: true,
      then: () =>
        number()
          .required(
            formatMessage(
              {
                id: "forms::donationOptionRequiredError",
                defaultMessage: "Donation option {option} is required",
              },
              {
                option: 1,
              }
            )
          )
          .min(
            minValue,
            formatMessage(
              {
                id: "forms::donationOption1Error",
                defaultMessage: "Minimum € {value}",
              },
              {
                value: 5,
              }
            )
          ),
      otherwise: () => {},
    })
    .nullable()
    .default(undefined)
export const recurringDonationAmount2 = ({ formatMessage, minValue }) =>
  number()
    .when("enableRecurringCampaign", {
      is: true,
      then: () =>
        number()
          .required(
            formatMessage(
              {
                id: "forms::donationOptionRequiredError",
                defaultMessage: "Donation option {option} is required",
              },
              {
                option: 2,
              }
            )
          )
          .min(
            minValue,
            formatMessage({
              id: "forms::donationOption2Error",
              defaultMessage: "Must be higher then option 1",
            })
          ),
      otherwise: () => {},
    })
    .nullable()
    .default(undefined)

export const recurringDonationAmount3 = ({ formatMessage, minValue }) =>
  number()
    .when("enableRecurringCampaign", {
      is: true,
      then: () =>
        number()
          .required(
            formatMessage(
              {
                id: "forms::donationOptionRequiredError",
                defaultMessage: "Donation option {option} is required",
              },
              {
                option: 3,
              }
            )
          )
          .min(
            minValue,
            formatMessage({
              id: "forms::donationOption3Error",
              defaultMessage: "Must be higher then option 1, 2",
            })
          ),
      otherwise: () => {},
    })
    .nullable()
    .default(undefined)

export const paymentAmount = ({ formatMessage, minValue, maxValue }) => {
  const min = minValue || 5
  const max = maxValue || 9999.99

  return number()
    .min(
      min,
      formatMessage(
        {
          id: "forms::donationMinAmountError",
          defaultMessage: "The amount must be at least {value}.",
        },
        {
          value: min,
        }
      )
    )
    .max(
      9999.99,
      formatMessage(
        {
          id: "forms::donationMaxAmountError",
          defaultMessage: "The amount must be lower than {value}.",
        },
        {
          value: max,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::donationAmountRequiredError",
        defaultMessage: "Amount is required.",
      })
    )
}

export const percentage = ({ formatMessage }) =>
  number()
    .required(
      formatMessage({
        id: "forms::dashboard::pacRatioMaxAmountRequired",
        defaultMessage: "Pac discount is required",
      })
    )
    .max(
      100,
      formatMessage(
        {
          id: "forms::dashboard::pacRatioMaxAmountError",
          defaultMessage:
            "The PAC discount must be less than or equal to {value}.",
        },
        {
          value: 100,
        }
      )
    )
    .min(
      0,
      formatMessage(
        {
          id: "forms::dashboard::pacRatioMinAmountError",
          defaultMessage:
            "The PAC discount must be more than or equal to {value}.",
        },
        {
          value: 0,
        }
      )
    )

export const cardholderName = ({ formatMessage }) =>
  string()
    .min(
      3,
      formatMessage(
        {
          id: "forms::cardholderNameLengthError",
          defaultMessage:
            "The cardholder name must be longer than {value} letters.",
        },
        {
          value: 3,
        }
      )
    )
    .matches(/^[a-z À-úŞıİğäöüÄÖÜß]*$/i, {
      excludeEmptyString: true,
      message: formatMessage({
        id: "forms::cardholderNameAlphaError",
        defaultMessage: "Cardholder name should only contain letters",
      }),
    })
    .required(
      formatMessage({
        id: "forms::cardholderNameRequiredError",
        defaultMessage: "The cardholder name is required.",
      })
    )
export const note = ({ formatMessage }) => string()

export const email = ({ formatMessage }) =>
  string()
    .email(
      formatMessage({
        id: "forms::emailInvalidError",
        defaultMessage: "Enter a correct email",
      })
    )
    .required(
      formatMessage({
        id: "forms::emailRequiredError",
        defaultMessage: "Enter your email",
      })
    )
    .nullable()

export const referralEmail = ({ formatMessage }) =>
  string()
    .email(
      formatMessage({
        id: "forms::referralEmailInvalidError",
        defaultMessage: "Enter a correct referral email",
      })
    )
    .required(
      formatMessage({
        id: "forms::referralEmailRequiredError",
        defaultMessage: "Enter referral's email",
      })
    )
    .nullable()

export const passwordRequired = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::passwordRequiredError",
      defaultMessage: "A password is required.",
    })
  )

export const taxIdRequired = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::taxIdRequiredError",
      defaultMessage: "A tax number is required.",
    })
  )

export const role = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms::roleRequiredError",
        defaultMessage: "Role is required.",
      })
    )
    .nullable()

export const password = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms::passwordRequiredError",
        defaultMessage: "Enter your password",
      })
    )
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, {
      excludeEmptyString: true,
      message: formatMessage(
        {
          id: "forms::passwordRulesError",
          defaultMessage:
            "Password must contain at least {uppercase} uppercase, {lowercase} lowercase character, {digits} digit and be at least {minLength} characters long",
        },
        {
          uppercase: 1,
          lowercase: 1,
          digits: 1,
          minLength: 8,
        }
      ),
    })

export const agreeTerms = ({ formatMessage }) =>
  boolean().test(
    "is-true",
    formatMessage({
      id: "forms::termsRequired",
      defaultMessage: "Accept terms of use",
    }),
    (value) => value === true
  )

export const country = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms::countryError",
        defaultMessage: "Enter the country you live in",
      })
    )
    .nullable()

export const city = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms::cityError",
        defaultMessage: "City is required",
      })
    )
    .nullable()

export const contactCountry = ({ formatMessage }) =>
  object()
    .required(
      formatMessage({
        id: "forms::countryError",
        defaultMessage: "Enter country you live in",
      })
    )
    .nullable()

export const contactCity = ({ formatMessage }) =>
  object().required(
    formatMessage({
      id: "forms::cityError",
      defaultMessage: "City is required",
    })
  )

export const address = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::addressError",
      defaultMessage: "Address is required",
    })
  )

export const phone = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::phoneError",
      defaultMessage: "Phone is required",
    })
  )

export const bankName = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::bankNameError",
      defaultMessage: "Bank name is required",
    })
  )
export const bankAccountName = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::legalNameAccount",
      defaultMessage: "Legal name of the account is required",
    })
  )

export const expirationMonth = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::expirationMonthError",
      defaultMessage: "Expiration Month is required",
    })
  )

export const expirationYear = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::expirationYearError",
      defaultMessage: "Expiration Year is required",
    })
  )

export const postalCode = ({ formatMessage }) =>
  string()
    .min(
      4,
      formatMessage(
        {
          id: "forms::postalCodeLengthError",
          defaultMessage: "Postal Code must be longer than { value } letters.",
        },
        {
          value: 4,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::postalCodeRequiredError",
        defaultMessage: "Postal Code is required.",
      })
    )

export const iban = ({ formatMessage, IBAN }) =>
  string()
    .required(
      formatMessage({
        id: "forms::ibanRequired",
        defaultMessage: "IBAN is required.",
      })
    )
    .test(
      "is-true",
      formatMessage({
        id: "forms::ibanInvalid",
        defaultMessage: "IBAN is invalid",
      }),
      (value) => {
        return IBAN.isValid(value)
      }
    )

export const fieldRequired = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms::fieldRequiredError",
        defaultMessage: "This field is required.",
      })
    )
    .nullable()

export const title = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::titleLengthError",
          defaultMessage: "The title must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::titleRequiredError",
        defaultMessage: "The title is required.",
      })
    )
    .nullable()

export const description = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::descriptionLengthError",
          defaultMessage:
            "The description must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::descriptionRequiredError",
        defaultMessage: "The description is required.",
      })
    )
    .notOneOf(
      ["<p></p>\n"],
      formatMessage({
        id: "forms::descriptionRequiredError",
        defaultMessage: "The description is required.",
      })
    )
export const textField = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "forms::textFieldLengthError",
          defaultMessage:
            "The text field must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .required(
      formatMessage({
        id: "forms::textFieldRequiredError",
        defaultMessage: "The text field is required.",
      })
    )

export const additionalInfo = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::additionalInfoRequiredError",
      defaultMessage: "The additional info is required.",
    })
  )

export const startDate = ({ formatMessage }) =>
  date()
    .required(
      formatMessage({
        id: "forms::startDateRequiredError",
        defaultMessage: "Choose a date",
      })
    )
    .nullable()
    .default(null)

export const category = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms::categoryRequiredError",
        defaultMessage: "Category is required.",
      })
    )
    .nullable()

export const campaignType = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms::campaign::campaignTypeRequiredError",
        defaultMessage: "Campaign type is required.",
      })
    )
    .nullable()

export const video = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms:videoRequiredError",
        defaultMessage: "Video is required.",
      })
    )
    .nullable()

export const website = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms:websiteRequiredError",
        defaultMessage: "Website is required.",
      })
    )
    .nullable()

export const imageRequired = ({ formatMessage }) =>
  array()
    .min(
      1,
      formatMessage({
        id: "forms:imageRequiredError",
        defaultMessage: "Choose at least one image for your campaign",
      })
    )
    .nullable()

export const imageOnlyRequired = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "forms::imageOnlyRequired",
        defaultMessage: "Image is required",
      })
    )
    .nullable()

export const countryRequired = ({ formatMessage }) =>
  string()
    .when("isWorldwide", {
      is: true,
      then: () => {},
      otherwise: () =>
        string().when("availableCountries", {
          is: (country) => !country || country?.length === 0,
          then: () =>
            string().when("availableCities", {
              is: (city) => !city || city?.length === 0,
              then: () =>
                string().required(
                  formatMessage({
                    id: "forms::countryCityRequired",
                    defaultMessage: "Country/city required",
                  })
                ),
              otherwise: () => {},
            }),
          otherwise: () => {},
        }),
    })
    .nullable()

export const communicationLanguage = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::communicationLanguage",
      defaultMessage: "Choose language",
    })
  )
export const accountTypeRequired = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "forms::accountTypeRequired",
      defaultMessage: "Please select your account type.",
    })
  )

export const availableCountries = ({ formatMessage }) =>
  array()
    .min(
      1,
      formatMessage({
        id: "forms::dashboard::availableCountriesMin",
        defaultMessage: "Please select at least 1 country",
      })
    )
    .required(
      formatMessage({
        id: "forms::dashboard::availableCountriesRequired",
        defaultMessage: "Please select available countries",
      })
    )
    .nullable()

export const goalAmount = ({ formatMessage }) =>
  number()
    .when("hasNoGoal", {
      is: false,
      then: () =>
        number().required(
          formatMessage({
            id: "forms::enterAmount",
            defaultMessage: "Enter an amount",
          })
        ),
      otherwise: () => {},
    })
    .nullable()
    .default(undefined)

export const endDate = ({ formatMessage }) =>
  date()
    .required(
      formatMessage({
        id: "forms::dashboard::endDateRequiredError",
        defaultMessage: "An end date is required.",
      })
    )
    .nullable()
    .default(null)

export const categories = ({ formatMessage }) =>
  array()
    .min(
      1,
      formatMessage({
        id: "forms::dashboard::categoriesMin",
        defaultMessage: "Please select at least 1 category",
      })
    )
    .required(
      formatMessage({
        id: "dashboard::campaignForm::validation::categoryRequired",
        defaultMessage: "A category is required.",
      })
    )

export const categoryList = ({ formatMessage }) =>
  array().min(
    1,
    formatMessage({
      id: "dashboard::campaignForm::validation::categoryRequired",
      defaultMessage: "A category is required.",
    })
  )

export const shortDescription = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "dashboard::campaignForm::validation::shortDescriptionMinLength",
          defaultMessage:
            "Short description must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .max(
      10000,
      formatMessage(
        {
          id: "dashboard::campaignForm::validation::shortDescriptionMaxLength",
          defaultMessage:
            "Short description must be shorter than { value } letters.",
        },
        {
          value: 10000,
        }
      )
    )
    .required(
      formatMessage({
        id: "dashboard::campaignForm::validation::shortDescriptionRequired",
        defaultMessage: "A short description is required.",
      })
    )
    .notOneOf(
      ["<p></p>\n"],
      formatMessage({
        id: "dashboard::campaignForm::validation::shortDescriptionRequired",
        defaultMessage: "A short description is required.",
      })
    )

export const campaignName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "dashboard::campaignForm::validation::nameMinLength",
          defaultMessage:
            "Campaign name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .max(
      128,
      formatMessage(
        {
          id: "dashboard::campaignForm::validation::nameMaxLength",
          defaultMessage: "Ensure this value has at most { value } characters.",
        },
        {
          value: 128,
        }
      )
    )
    .required(
      formatMessage({
        id: "dashboard::campaignForm::validation::nameRequired",
        defaultMessage: "Campaign name is required.",
      })
    )
    .nullable()

export const eventName = ({ formatMessage }) =>
  string()
    .min(
      2,
      formatMessage(
        {
          id: "dashboard::campaignForm::validation::eventNameMinLength",
          defaultMessage: "Event name must be longer than { value } letters.",
        },
        {
          value: 2,
        }
      )
    )
    .max(
      128,
      formatMessage(
        {
          id: "dashboard::campaignForm::validation::nameMaxLength",
          defaultMessage: "Ensure this value has at most { value } characters.",
        },
        {
          value: 128,
        }
      )
    )
    .required(
      formatMessage({
        id: "dashboard::campaignForm::validation::eventNameRequired",
        defaultMessage: "Event name is required.",
      })
    )
    .nullable()

export const offerLink = ({ formatMessage }) =>
  string()
    .matches(
      urlRegex,
      formatMessage({
        id: "dashboard::productForm::productOfferLinkInvalid",
        defaultMessage: "Not a valid address.",
      })
    )
    .required(
      formatMessage({
        id: "dashboard::productForm::productOfferLinkRequired",
        defaultMessage: "An offer link is required for external offers.",
      })
    )

export const urlFormat = ({ formatMessage }) =>
  string().matches(
    urlRegex,
    formatMessage({
      id: "dashboard::merchantForm::urlFormatInvalid",
      defaultMessage: "Not a valid address.",
    })
  )

export const urlFormatRequired2 = ({ formatMessage }) =>
  string()
    .matches(
      urlRegex,
      formatMessage({
        id: "dashboard::merchantForm::urlFormatInvalid",
        defaultMessage: "Not a valid address.",
      })
    )
    .required(
      formatMessage({
        id: "forms::requiredField",
        defaultMessage: "This field is required",
      })
    )

export const urlFormatRequired = ({ formatMessage }) =>
  string()
    .matches(
      urlRegex,
      formatMessage({
        id: "dashboard::merchantForm::urlFormatInvalid",
        defaultMessage: "Not a valid address.",
      })
    )
    .required(
      formatMessage({
        id: "dashboard::merchantForm::linkRequired",
        defaultMessage: "Link is required",
      })
    )

export const nullableString = () => string().nullable()

export const donationTier = ({
  formatMessage,
  locale,
  isEnglishSectionOpen,
  isItalianSectionOpen,
  isSpanishSectionOpen,
}) =>
  array()
    .required(
      formatMessage({
        id: "forms::donationOptionRequired",
        defaultMessage: "Donation entry option is required.",
      })
    )
    .of(
      object().shape({
        donationAmount: number()
          .min(
            5,
            formatMessage(
              {
                id: "forms::donationMinAmountError",
                defaultMessage: "The amount must be at least {value}.",
              },
              {
                value: 5,
              }
            )
          )
          .max(
            9999.99,
            formatMessage(
              {
                id: "forms::donationMaxAmountError",
                defaultMessage: "The amount must be lower than {value}.",
              },
              {
                value: 9999.99,
              }
            )
          )
          .required(
            formatMessage({
              id: "forms::entryDonationRequiredError",
              defaultMessage: "Put an entry donation",
            })
          )
          .nullable()
          .default(undefined),
        maxTickets: number()
          .when("isUnlimitedTickets", {
            is: false,
            then: () =>
              number()
                .required(
                  formatMessage({
                    id: "forms::ticketNumber",
                    defaultMessage: "Number of tickets",
                  })
                )
                .min(
                  1,
                  formatMessage(
                    {
                      id: "forms::ticketNumber",
                      defaultMessage: "Number of tickets",
                    },
                    {
                      value: 1,
                    }
                  )
                ),
            otherwise: () => {},
          })
          .nullable()
          .default(undefined),
        // descriptionEn:
        //   (locale === "en" || isEnglishSectionOpen) &&
        //   description({ formatMessage }).nullable().default(undefined),
        // descriptionIt:
        //   (locale === "it" || isItalianSectionOpen) &&
        //   description({ formatMessage }).nullable().default(undefined),
        // descriptionEs:
        //   (locale === "es" || isSpanishSectionOpen) &&
        //   description({ formatMessage }).nullable().default(undefined),
      })
    )

export const produtVariant = ({
  formatMessage,
  locale,
  isEnglishSectionOpen,
  isItalianSectionOpen,
  isSpanishSectionOpen,
}) =>
  array()
    .required(
      formatMessage({
        id: "forms::produtVariantRequired",
        defaultMessage: "Produt variant is required.",
      })
    )
    .of(
      object().shape({
        price: number()
          .min(
            10,
            formatMessage(
              {
                id: "forms::donationMinAmountError",
                defaultMessage: "The amount must be at least {value}.",
              },
              {
                value: 10,
              }
            )
          )
          .max(
            9999.99,
            formatMessage(
              {
                id: "forms::donationMaxAmountError",
                defaultMessage: "The amount must be lower than {value}.",
              },
              {
                value: 9999.99,
              }
            )
          )
          .required(
            formatMessage({
              id: "forms::entryDonationRequiredError",
              defaultMessage: "Put an entry donation",
            })
          )
          .nullable()
          .default(undefined),
        maxTickets: number()
          .when("isUnlimitedTickets", {
            is: false,
            then: () =>
              number()
                .required(
                  formatMessage({
                    id: "forms::donationAmountRequiredError",
                    defaultMessage: "Amount is required.",
                  })
                )
                .min(
                  1,
                  formatMessage(
                    {
                      id: "forms::donationMinAmountError",
                      defaultMessage: "The amount must be at least {value}.",
                    },
                    {
                      value: 1,
                    }
                  )
                )
                .max(
                  9999,
                  formatMessage(
                    {
                      id: "forms::donationMaxAmountError",
                      defaultMessage: "The amount must be lower than {value}.",
                    },
                    {
                      value: 9999,
                    }
                  )
                ),
            otherwise: () => {},
          })
          .nullable()
          .default(undefined),

        pacDiscount: percentage({ formatMessage }),

        // descriptionEn:
        //   (locale === "en" || isEnglishSectionOpen) &&
        //   description({ formatMessage }).nullable().default(undefined),
        // descriptionIt:
        //   (locale === "it" || isItalianSectionOpen) &&
        //   description({ formatMessage }).nullable().default(undefined),
        // descriptionEs:
        //   (locale === "es" || isSpanishSectionOpen) &&
        //   description({ formatMessage }).nullable().default(undefined),
      })
    )

export const dateRequired = ({ formatMessage }) =>
  date()
    .required(
      formatMessage({
        id: "forms::chooseDate",
        defaultMessage: "Choose a date",
      })
    )
    .nullable()
    .default(undefined)

export const dateRequired2 = ({ formatMessage }) =>
  date()
    .required(
      formatMessage({
        id: "forms::chooseDate",
        defaultMessage: "Choose a date",
      })
    )
    .default(undefined)

export const dateRequiredWhenNoDeadline = ({ formatMessage }) =>
  date()
    .when("hasNoDeadline", {
      is: false,
      then: () =>
        date().required(
          formatMessage({
            id: "forms::chooseDate",
            defaultMessage: "Choose a date",
          })
        ),
      otherwise: () => {},
    })
    .nullable()
    .default(undefined)

export const addressRequired = ({ formatMessage }) =>
  string()
    .when("isVirtualEvent", {
      is: false,
      then: () =>
        string().required(
          formatMessage({
            id: "forms::addressRequiredError",
            defaultMessage: "Type the address",
          })
        ),
      otherwise: () => {},
    })
    .nullable()
    .default("")

export const virtualLinkRequired = ({ formatMessage }) =>
  string()
    .when("isVirtualEvent", {
      is: true,
      then: () =>
        string().required(
          formatMessage({
            id: "forms::linkRequiredError",
            defaultMessage: "Link is required",
          })
        ),
      otherwise: () => {},
    })
    .nullable()
    .default("")

export const supporterCauseRequired = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "dashboard::campaignChooseCause::validation::supporterCauseRequired",
        defaultMessage: "Your cause is required.",
      })
    )
    .nullable()

export const numberOfDaysRequired = ({ formatMessage }) =>
  number()
    .when("hasNoDeadline", {
      is: false,
      then: () =>
        number()
          .required(
            formatMessage({
              id: "dashboard::campaignChooseCause::validation::numberOfDaysRequired",
              defaultMessage: "Number of days is required.",
            })
          )
          .min(
            1,
            formatMessage(
              {
                id: "forms::minNumberOfDaysError",
                defaultMessage: "The number of days must be at least {value}.",
              },
              {
                value: 1,
              }
            )
          )
          .max(
            MAX_DAYS_END_EVENT,
            formatMessage(
              {
                id: "forms::maxNumberOfDaysError",
                defaultMessage:
                  "The number of days must be lower than {value}.",
              },
              {
                value: MAX_DAYS_END_EVENT,
              }
            )
          ),
      otherwise: () => {},
    })
    .nullable()
    .default(undefined)

export const partnerRequired = ({ formatMessage }) =>
  string()
    .required(
      formatMessage({
        id: "dashboard::campaign::pleaseSelectCharity",
        defaultMessage: "Pick select a charity",
      })
    )
    .nullable()

export const donationOption1 = ({ formatMessage, minValue }) => {
  const min = minValue || 5

  return number()
    .required(
      formatMessage(
        {
          id: "forms::donationOptionRequiredError",
          defaultMessage: "Donation option {option} is required",
        },
        {
          option: 1,
        }
      )
    )
    .min(
      min,
      formatMessage(
        {
          id: "forms::donationOption1Error",
          defaultMessage: "Minimum € {value}",
        },
        {
          value: min,
        }
      )
    )
    .nullable()
}

export const donationOption2 = ({ formatMessage, minValue1 }) => {
  const min = parseFloat(minValue1) + 0.01

  return number()
    .required(
      formatMessage(
        {
          id: "forms::donationOptionRequiredError",
          defaultMessage: "Donation option {option} is required",
        },
        {
          option: 2,
        }
      )
    )
    .min(
      min,
      formatMessage({
        id: "forms::donationOption2Error",
        defaultMessage: "Must be higher then donation option 1",
      })
    )
    .nullable()
}

export const donationOption3 = ({ formatMessage, minValue1, minValue2 }) => {
  const min = parseFloat(minValue2) + 0.01

  return number()
    .required(
      formatMessage(
        {
          id: "forms::donationOptionRequiredError",
          defaultMessage: "Donation option {option} is required",
        },
        {
          option: 3,
        }
      )
    )
    .min(
      min,
      formatMessage({
        id: "forms::donationOption3Error",
        defaultMessage: "Must be higher then donation option 1, 2",
      })
    )
    .nullable()
}

export const campaignConditions = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "dashboard::referralCodes::validation::campaignConditionsRequired",
      defaultMessage: "Please select at least 1 campaign",
    })
  )

export const offerConditions = ({ formatMessage }) =>
  string().required(
    formatMessage({
      id: "dashboard::referralCodes::validation::offerConditionsRequired",
      defaultMessage: "Please select at least 1 offer",
    })
  )
