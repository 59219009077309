import React, { useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useFormikContext } from "formik"
import { TextInput, Dropdown, BaseError, Button } from "@tmu/components/common"
import { useAddressValidation } from "@tmu/hooks"
import { useCountries, useTypeConstants, useToast } from "@tmu/hooks"
import {
  StyledContactForm,
  StyledField,
  StyledButtonContainer,
} from "./index.styles"

const ContactForm = ({ setContactFormVisible }) => {
  const { values, setValues } = useFormikContext()
  const { formatMessage } = useIntl()
  const { checkAddress, addressError, setAddressError } = useAddressValidation()
  const [addressLoading, setAddressLoading] = useState(false)
  const [addressLine1, setAddressLine1] = useState()
  const [country, setCountry] = useState()
  const [postCode, setPostCode] = useState()
  const { error, success } = useToast()

  let {
    countries: allCountries,
    countryOptions: getCountryOptions,
    getCountries,
  } = useCountries()
  let countries = allCountries

  useEffect(() => {
    getCountries()
  }, [])

  let {
    operatingCountries: restrictCountries,
    getCountryOptions: restrictCountryOptions,
  } = useTypeConstants({})

  countries = restrictCountries
  if (Array.isArray(countries) && countries?.length > 0) {
    const empty = countries[0]
    empty.description = ""
  }
  getCountryOptions = restrictCountryOptions

  const currentCountry = useCallback(
    countries?.find((item) => (item?.id || item?.shortCode) === country)
  )

  const handleSubmit = async () => {
    setAddressError()
    setAddressLoading(true)
    const correctedAddressData = await checkAddress(
      addressLine1,
      postCode,
      country
    )
    const resultData = correctedAddressData?.resultData

    const { Latitude, Longitude } = resultData
    if (
      !correctedAddressData.result ||
      correctedAddressData?.result?.length === 0
    ) {
      error(
        err?.message ??
          formatMessage({
            id: "dashboard::campaignForm::errorMessage",
            defaultMessage: "An error occurred",
          })
      )
      setAddressLoading(false)
      return
    }

    setValues({
      ...values,
      addressLine1:
        correctedAddressData?.resultData?.FormattedAddress?.replaceAll(
          ";",
          ", "
        ),
      city: correctedAddressData?.city,
      country,
      postCode,
      location: `SRID=4326;POINT(${Longitude} ${Latitude})`,
    })
    setAddressLoading(false)
    setContactFormVisible(false)
  }

  return (
    <StyledContactForm>
      <StyledField>
        <TextInput
          newDesign
          data-testid="input-addressLine1"
          id="addressLine1"
          name="addressLine1"
          className="float-container full-width"
          value={addressLine1 || ""}
          onChange={(e) => setAddressLine1(e.target.value)}
          label={formatMessage({
            id: "dashboard::forms::address",
            defaultMessage: "Address",
          })}
        />
      </StyledField>

      <StyledField className="country" data-testid="input-country">
        <p className="caption">
          <FormattedMessage
            id="dashboard::forms::country"
            defaultMessage="Country"
          />
        </p>
        <Dropdown
          data-testid={`select-country`}
          id="country"
          name="country"
          className="select-field custom-select"
          label={formatMessage({
            id: "dashboard::forms::country",
            defaultMessage: "Country",
          })}
          options={getCountryOptions}
          value={{
            label: currentCountry?.description || currentCountry?.label || "",
            value: currentCountry?.id || currentCountry?.value || "",
          }}
          onChange={(country) => {
            setCountry(country?.value)
          }}
          isClearable
        />
      </StyledField>
      <StyledField className="postal">
        <TextInput
          newDesign
          data-testid="input-zip-code"
          id="postCode"
          name="postCode"
          className="float-container"
          value={postCode || ""}
          onChange={(e) => setPostCode(e.target.value)}
          label={formatMessage({
            id: "dashboard::forms::postalCode",
            defaultMessage: "Postal Code",
          })}
        />
      </StyledField>
      {addressLoading ? (
        <BaseError
          message={formatMessage({
            id: "forms::address::validating",
            defaultMessage: "Please wait while your address is being validated",
          })}
        />
      ) : null}
      {addressError ? <BaseError message={addressError} /> : null}
      <StyledButtonContainer>
        <Button
          variant="outlined"
          label="cancel"
          color="transparent"
          onClick={() => setContactFormVisible(false)}
          style={{ marginLeft: "unset" }}>
          <FormattedMessage
            id="dashboard::forms::cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          color="carrot"
          label="save"
          onClick={() => handleSubmit()}
          disabled={addressLoading || !addressLine1 || !country || !postCode}>
          <FormattedMessage
            id="dashboard::forms::saveAddress"
            defaultMessage="Save Address"
          />
        </Button>
      </StyledButtonContainer>
    </StyledContactForm>
  )
}

export default ContactForm
